@import '../../../styles/variables';

.email-confirm {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid $grey-border;
  box-shadow: inset 5px 0px 28px rgba(150, 140, 114, 0.2);
  backdrop-filter: blur(13.5px);
  border-radius: 17px;
  padding: 48px 130px 43px;
  margin: 0 20px;
  overflow-y: auto;
  max-height: 80vh;

  @media screen and (max-width: $tablet-lg) {
    padding: 22px;
    margin: 0 10px;
  }

  .title {
    text-align: center;
    margin-bottom: 26px;

    @media screen and (max-width: $tablet) {
      text-align: left;
      padding-right: 40px;
    }
  }

  .close-icon {
    position: absolute;
    right: 28px;
    top: 21px;
    cursor: pointer;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .text {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: $color-dark-gold;
  }

  .text__email {
    line-height: 26px;
    text-transform: uppercase;
    margin: 16px 0 50px;
  }

  .input {
    border: 1px solid $color-gold;
    border-top: none;
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 300;
    font-size: 37px;
    line-height: 90%;
    letter-spacing: -0.03em;
    color: $color-gold;
    text-align: center;

    margin: 24px 0 150px;
    padding: 12px 40px;

    @media screen and (max-width: $tablet) {
      margin: 24px 0;
    }
  }

  .description {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: $grey-text;
    margin-bottom: 24px;

    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }

  .description_mobile {
    display: none;

    @media screen and (max-width: $mobile-lg) {
      display: flex;
      margin: 20px 0 0;
      justify-content: center;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $mobile-lg) {
      flex-direction: column-reverse;
      gap: 20px;
    }
  }

  .confirm-button {
    max-width: 342px;
    width: 100%;
  }
}