@import '../../../../../../styles/variables';

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #1E1E1E;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

.filters-bottom-sheet__content {
  padding: 0 20px 20px;
}

.filters-bottom-sheet__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.filters-bottom-sheet__title {
  font-family: 'Roundo';
  font-weight: 400;
  font-size: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $color-gold;
  padding-top: 7px;
}

.filters-bottom-sheet__search {
  display: flex;
  align-items: center;
  height: 48px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8.5px);
  border-radius: 50px;
}

.filters-bottom-sheet__search-input {
  margin-left: 7px;
  font-family: 'Switzer';
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #616156;
}

.filters-bottom-sheet__divider {
  width: 100%;
  height: 1px;
  background: $grey-border;
  margin: 27px 0;
}

.filters-bottom-sheet__content-title {
  font-family: 'Switzer';
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  color: $color-gold;
  margin-bottom: 1rem;
}

.filters-bottom-sheet__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.filters-bottom-sheet__switcher {
  margin-top: 15px;
  display: flex;
}

.filters-bottom-sheet__switcher-content {
  margin-left: 16px;
}

.filters-bottom-sheet__switcher-content-text {
  font-family: 'Switzer';
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: $color-dark-gold;
}

.filters-bottom-sheet__switcher-content-text_active {
  color: $color-green;
}

.filters-bottom-sheet__switcher-content-text_nails {
  color: $color-grey;
}
