@import '../../../../styles/variables.scss';

.main-info {
  max-width: $laptop-lg;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;

  .divider {
    margin: 43px 0 4px;
  }
}

.main-info__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4em;
  padding: 30px;
  margin-top: 10em;

  @media screen and (max-width: $tablet-lg) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: $mobile-lg) {
    margin-top: 87px;
    padding: 0 15px;
    gap: 27px;
  }
}

.main-info__text-block {
  display: flex;
  flex-flow: column;
  gap: 35px;

  @media screen and (max-width: $mobile-lg) {
    gap: 0px;
  }
}

.main-info__title {
  @media screen and (max-width: $mobile-lg) {
    text-align: center;
  }
}

.main-info__video-block {
  width: 100%;

  &_desktop {
    min-height: 470px;
    height: 100% !important;
    display: block;
    
    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }
  
  &_mobile {
    display: none;

    @media screen and (max-width: $tablet-lg) {
      display: block;
    }

    @media screen and (max-width: $mobile-lg) {
      margin: 27px 0;
      min-height: 204px;
      height: 100% !important;
    }
  }
}

.main-info-description {
  padding-left: 8em;

  @media screen and (max-width: $mobile-lg) {
    padding-left: 57px;
    margin-top: 17px;
  }
}
