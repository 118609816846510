@import '../../../../../styles/variables.scss';

.list {
  display: flex;
  flex-flow: column;
  padding-top: 47px;

  @media screen and (max-width: $tablet) {
    padding-top: 27px;
  }
}

.list__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 31px;

  border-bottom: 1px solid $color-grey;
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  text-transform: uppercase;
  color: $color-grey;
}

.list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #272722;

  font-family: Switzer;
  font-weight: 300;
  font-size: 37px;
  line-height: 90%;
  color: $color-gold;
  text-transform: uppercase;
  padding: 20px 0;

  @media screen and (max-width: $tablet) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 10px 0;
  }
}

.list__item_tablet {
  display: flex;

  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.list__item_mobile {
  display: none;

  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  @media screen and (max-width: $mobile-lg) {
    gap: 5px;
  }
}

.list__item input {
  height: 40px;

  font-family: Switzer;
  font-weight: 300;
  font-size: 37px;
  line-height: 90%;
  
  text-align: center;
  text-transform: uppercase;

  color: $color-gold;

  @media screen and (max-width: $tablet) {
    font-size: 24px;
    height: auto;
    line-height: 100%;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}

.list__item input[type='text'] {
  width: 300px;

  @media screen and (max-width: $laptop-sm) {
    width: 220px;
  }

  @media screen and (max-width: $tablet) {
    text-align: right;
  }
}

.list__item input[type='number'] {
  text-align: left;
  width: 120px;

  @media screen and (max-width: $tablet) {
    text-align: right;
  }
}

.list__item input::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 37px;
  line-height: 90%;

  text-transform: uppercase;
  text-align: center;

  color: #272722;

  @media screen and (max-width: $tablet) {
    font-size: 24px;
    height: auto;
    line-height: 100%;
    text-align: right;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}

.list__item input[type='number']::placeholder {
  text-align: left;

  @media screen and (max-width: $tablet) {
    font-size: 17px;
    text-align: right;
  }
}

.list__item-content_mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  align-items: center;
}

.list__item-text_mobile {
  font-size: 24px;
  line-height: 100%;

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}