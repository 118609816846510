@import '../../styles/variables.scss';

.statistic {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 10px;
  margin-top: 134px;
  padding: 30px;
  overflow-y: hidden;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    grid-template-rows: 1fr auto;
    padding: 15px;
  }
}

.statistic > h2 {
  font-size: 5em;
  font-weight: 300;
  line-height: 100%;
  color: $color-gold;
  text-align: center;

  -webkit-animation: fadein 1.8s linear forwards;
  animation: fadein 1.8s linear forwards;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 47px;
    padding: 20px;
  }
}

.order-list_fade-in {
  -webkit-animation: fadein .4s linear forwards;
  animation: fadein .4s linear forwards;
  display: grid;
  z-index: 5;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}