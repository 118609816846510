@import '../../styles/variables';

.order {
  margin-top: 134px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: $tablet-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.order__nails {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
}

.order__title {
  display: flex;
  font-family: 'Roundo';
  font-style: normal;
  font-weight: 400;
  font-size: 37px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: $color-gold;
  margin-bottom: 18px;
  align-self: center;
}

.order__block {
  grid-column: span 3/7;
  overflow-x: hidden;

  @media screen and (min-width: 1080px) and (max-width: 3840px) {
    height: 78vh;
  }
}