@import '../../styles/variables.scss';

.exclusive-partner {
	display: flex;
  flex-flow: column;
	background: rgb(0 0 0 / 30%);
	justify-self: end;
	backdrop-filter: blur(35px);
	gap: 6.5px;
  border-radius: 17px 0px 0px 17px;
}

.exclusive-partner :first-child {
  color: $color-light-grey;
}

.exclusive-partner :last-child {
  color: $color-grey;
}

.exclusive-partner svg {
	width: 196px;
  height: 172px;
}

.exclusive-partner__bordered {
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color:  #414141;
  box-shadow: inset 3px 0px 27px rgba(136, 125, 94, 0.4);
  backdrop-filter: blur(35px);
  padding: 17px;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    gap: 2.5px;
  }
}

.exclusive-partner__bordered svg {
  @media screen and (min-height: 600px) and (max-height: 640px) {
    width: 100px;
    height: 88px;
  }

  @media screen and (min-height: 640px) and (max-height: 700px) {
    width: 120px;
    height: 108px;
  }

  @media screen and (min-height: 700px) and (max-height: 740px) {
    width: 150px;
    height: 128px;
  }

  @media screen and (min-height: 740px) and (max-height: 800px) {
    width: 180px;
    height: 158px;
  }

  @media screen and (max-width: 1280px) {
    width: 114px;
    height: 80px;
  }

  @media screen and (max-width: 1366px) {
    width: 140px;
    height: 138px;
  }
}

.exclusive-partner__cr7 {
  padding: 10px;
  @media screen and (min-height: 600px) and (max-height: 800px) {
    gap: 2.5px;
  }
  svg {
    @media screen and (min-height: 600px) and (max-height: 640px) {
      width: 100px;
      height: 88px;
    }

    @media screen and (min-height: 640px) and (max-height: 700px) {
      width: 120px;
      height: 108px;
    }
  
    @media screen and (min-height: 700px) and (max-height: 740px) {
      width: 150px;
      height: 128px;
    }

    @media screen and (min-height: 740px) and (max-height: 800px) {
      width: 180px;
      height: 158px;
    }
  
    @media screen and (max-width: 1280px) {
      width: 114px;
      height: 80px;
    }
  
    @media screen and (max-width: 1366px) {
      width: 140px;
      height: 138px;
    }
  }
}

.exclusive-partner_mobile {
  display: none;
}

@media screen and (min-width: 280px) and (max-width: 880px) {
  .exclusive-partner {
    display: none;
  }

  .exclusive-partner_mobile {
    display: flex;
    flex-flow: column;
    justify-content: center;
    z-index: 2;
  }

  .exclusive-partner_mobile > svg {
    width: 5em;
    height: 5em;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0px 0px 10px rgba(62, 62, 55, 0.25);
    backdrop-filter: blur(13.5px);
    border-radius: 17px;
  }

  .exclusive-partner_mobile > span {
    font-family: Switzer;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    
    text-align: right;
    letter-spacing: -0.01em;
    
    color: #808080;    
  }
}