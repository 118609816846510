@import '../../../styles/variables.scss';

.first-step {
  padding: 60px 30px;

  @media screen and (max-width: $tablet) {
    padding: 0px 15px;
  }

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 87px;
  }
}

.first-step__sub_text {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: $color-gold;
}

.first-step__form {
  grid-column: span 3/7;
}

.first-step_header {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: baseline;
  gap: 17px;
}

.first-step_header h2 {
  @media screen and (max-width: $tablet) {
    font-size: 34px;
    line-height: 100%;
  }
}

.first-step_header h4 {
  @media screen and (max-width: $tablet) {
    font-size: 24px;
    line-height: 140%;
  }
}

.first-step_header :nth-child(2) {
  grid-column: span 2;
}

.first-step_header :last-child {
  grid-row-start: 2;
  grid-column: span 2;

  @media screen and (max-width: $tablet) {
    grid-column: span 3;
  }
}