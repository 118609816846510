@import '../../../../styles/variables';

.members {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.members > h2 {
  margin-top: 0 !important;
}

.members .button {
  margin-top: 47px;
}

.members .wavy-text {
  padding: 0 15px;
}
