@import '../variables.scss';

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.text-gold {
  color: $color-gold;
}