@import '../../../../../../styles/variables.scss';

.nail-info {
  display: flex;
  flex-flow: column;
  grid-row-start: 2;
  z-index: 8;
  width: 50%;

  @media screen and (min-width: 375px) and (max-width: 389px) {
    height: 85vh;
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    height: 90vh;
  }

  @media screen and (min-width: 412px) and (max-width: 414px) {
    height: 90vh;
  }
}

.golden-cristiano-portrait__search-icon__mobile {
  display: grid;
  grid-column-start: 1;
  grid-row-start: 2;
  align-self: flex-end;
  z-index: 4;
}

.nail-info__empty-block {
  display: flex;
  flex-flow: column;
  grid-row-start: 1;
  z-index: 2;
  gap: 20px;

  border-radius: 17px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 0px 10px rgba(62, 62, 55, 0.25);
  backdrop-filter: blur(8.5px);
  padding: 26px 12px;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    display: none;
  }
}

.nail-info_block {
  display: flex;
  flex-flow: column;
  gap: 1em;
  border-radius: 17px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 0px 10px rgba(62, 62, 55, 0.25);
  backdrop-filter: blur(8.5px);
  padding: 26px 12px;

  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    gap: 8px;
  }
}

.nail-info_bordered {
  border: 1px solid #3E3E37;
}

.nail-info__close-btn {
  display: none;
}

.nail-info__title {
  font-family: Roundo;
  font-weight: 300;
  font-size: 67px;
  line-height: 90%;

  letter-spacing: -0.03em;
  text-transform: uppercase;

  color: $color-gold;

  @media screen and (min-width: 375px) {
    font-size: 54px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 38px;
  }

  @media screen and (min-width: 1360px) {
    font-size: 42px;

    // @media screen and (min-height: 600px) and (max-height: 800px) {
    //   font-size: 32px;
    // }  
  }
}

.nail-info__notice {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 100%;
  color: $color-white;

  @media screen and (min-width: 1280px) {
    font-size: 18px;
  }
}

.empty-block {
  height: 100%;
  display: flex;
  align-items: center;
}

.nail-info__status-available {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  color: $color-green;

  // @media screen and (min-height: 600px) and (max-height: 800px) {
  //   font-size: 12px;
  // }  
}

.nail-info__nail-icon {
  display: flex;
  justify-content: center;
}

.nail-info__nail-icon > img {
  height: 6em;
}

.nail-info__price-block {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  gap: 8px;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    gap: 0;
  }
}

.nail-info__price-input-block {
  display: flex;
  flex-flow: column;
  position: relative;
}

.nail-info__price-input-block > input {
  width: 94%;
  height: 42px;
  border-radius: 57px;
  font-family: Switzer;
  font-weight: 300;
  font-size: 30px;
  line-height: 100%;
  color: $color-gold;
  padding-left: 10px;
  z-index: 1;
  // @media screen and (min-height: 600px) and (max-height: 800px) {
  //   height: 32px;
  // }
}

.nail-info__price-input-block > input::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 30px;
  line-height: 100%;
  color: #3E3E37;

  // @media screen and (min-height: 600px) and (max-height: 800px) {
  //   font-size: 18px;
  // }  
}

.nail-info__price-block-error {
  height: 42px;
  width: 90%;
  border: 2px solid rgba(198, 182, 137, .4);
  border-radius: 54px;
  top: 0;
  left: 50% - (90% / 2);
  position: absolute;
  bottom: 24px;
  transform: scale(0.1, 0.1);
  opacity: 0;
  animation: ring-1 3000ms ease-out infinite;
  z-index: -1;
  &:nth-of-type(2) {
    animation-delay: 600ms;
  }
}

@keyframes ring-1 {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.nail-info__buy-btn {
  display: flex;
  justify-content: center;
  border-top: 1px solid #3E3E37;
  padding-top: 17px;

  // @media screen and (min-height: 600px) and (max-height: 800px) {
  //   padding-top: 8px;
  // }
}

.nail-info__reserved-item {
  display: flex;
  flex-flow: column;
  gap: 7px;
}

.nail-info__close {
  display: none;
}

.nail-info__label {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;

  color: $color-grey;
}
.nail-info__value {
  display: flex;
  gap: 4px;
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;

  color: $color-gold;
  text-decoration: none;
}

.nail-info__buy-btn .button-primary_grand {
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    font-size: 14px;
    padding: 10px 18px 10px;
  }
  @media screen and (min-width: 1920px) and (max-width: 2048px) {
    font-size: 22px;
  }
  @media screen and (min-width: 2048px) and (max-width: 2500px) {
    font-size: 24px;
  }
}

.nail-info__status {
  @media screen and (min-height: 600px) and (max-height: 1298px) {
    font-size: 14px;
  }  
}

.nail-info__description {
  // @media screen and (min-height: 600px) and (max-height: 800px) {
  //   font-size: 12px;
  // }  
}

@media screen and (min-width: 280px) and (max-width: 834px) {
  .nail-info {
    position: absolute;
    justify-content: space-between;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border-radius: 13px;
    width: auto;
    
    z-index: 3;
  }

  .nail-info :not(:last-child) {
    align-items: center;
  }

  .nail-info__title {
    text-align: center;
  }
  
  .nail-info__status-available, .nail-info__status {
    text-align: center;
    font-size: 12px;

    @media screen and (min-height: 600px) and (max-height: 800px) {
      font-size: 10px;
    }  
  }

  .nail-info__close-btn {
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .nail-info__reserved-item {
    align-items: center;
    padding-bottom: 30px;
  }

  .nail-info_bordered {
    border: 1px solid #3E3E37;
    height: 100%;
  }

  .nail-info_block {
    display: grid;
    gap: 0;
    padding: 12px 12px 60px 12px;
    background: radial-gradient(69.21% 50% at 50% 50%, #404040 0%, #000000 100%);

    @media screen and (min-width: 280px) and (max-width: 800px) {
      // padding: 12px 12px 90px 12px;
      height: 100%;
    }
  }

  .nail-info__price-block {
    padding: 10px 22px 4px;
  }

  .nail-info__price-input-block > input {
    text-align: center;
  }

  .nail-info__close {
    display: flex;
  }

  .opened {
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
    display: flex;
    z-index: 13;
  }

  @-webkit-keyframes fadein {
    100% { opacity: 1; }
    50% { opacity: 0; }
  }
    
  @keyframes fadein {
    100% { opacity: 0; }
    50% { opacity: 1; }
  }

  .closed {
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
    z-index: 0;
    display: none;
  }

  @-webkit-keyframes fadeout {
    100% { opacity: 0; display: none; }
    50% { opacity: 1; }
  }
    
  @keyframes fadeout {
    100% { opacity: 0; display: none; }
    50% { opacity: 1; }
  }
}