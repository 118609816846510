@import '../../styles/variables';

.participants {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 30px;

  h2 {
    font-size: 97px;
    font-weight: 300;
    line-height: 100%;
    color: $color-gold;
    text-align: center;
    -webkit-animation: fadein 1.8s linear forwards;
    animation: fadein 1.8s linear forwards;
    margin-top: 196px;

    @media screen and (min-width: 320px) and (max-width: 820px) {
      font-size: 47px;
      padding: 20px;
      margin-top: 80px;
      padding: 0 15px;
    }
  }

  .participants__dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $grey-text;

    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }

  .participants__dot_mobile {
    @media screen and (max-width: $tablet-lg) {
      display: block;
    }
  }

  table {
    width: 100%;
    display: flex;
    // padding: 0 30px;
    margin: 86px 0;

    @media screen and (max-width: $mobile-lg) {
      padding: 0 15px;
    }

    @media screen and (min-width: 280px) and (max-width: 800px) {
      margin-top: 0px;
    }
  }

  tbody {
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 24px 17px;
    border-bottom: 1px solid $grey-border;

    @media screen and (max-width: $tablet-lg) {
      flex-direction: column;
      padding: 12px 0;
      gap: 4px;
    }
  }

  td {
    text-align: center;

    @media screen and (max-width: $tablet-lg) {
      text-align: left;
    }
  }

  .td-nail {
    width: 320px;
    text-align: start;

    @media screen and (max-width: $tablet-lg) {
      width: 100%;
    }
  }

  .td-name {
    display: flex;
    align-items: center;
    width: 17%;
    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }

  .td-country {
    display: flex;
    justify-content: end;
    width: 13%;
    @media screen and (max-width: $tablet-lg) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .td-info {
    width: 49%;
    display: flex;
    align-items: center;
    gap: 17px;
    align-content: center;

    @media screen and (max-width: $tablet-lg) {
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }

    & a {
      display: flex;
    }
  }

  .social {
    display: flex;
    align-items: center;
    gap: 17px;
  }

  .flag {
    margin-left: 17px;

    @media screen and (max-width: $mobile-lg) {
      margin-left: 0;
    }
  }

  .text {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    letter-spacing: -0.01em;
    color: $color-white-secondary;
    text-decoration: none;
  }

  .text_gold {
    color: $color-dark-gold;
  }

  .text_mobile {
    display: none;

    @media screen and (max-width: $tablet-lg) {
      display: block;
    }
  }

  .pagination__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }

  .pagination {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 10px;

    @media screen and (max-width: $mobile-lg) {
      gap: 5px;
    }

    li {
      list-style-type: none;
      border: 1px solid $color-gold;
      padding: 5px;
      width: 25px;
      height: 25px;
      text-align: center;
      cursor: pointer;

      @media screen and (max-width: $mobile-lg) {
        width: 16px;
        height: 16px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
      }

      &:hover {
        background: $color-dark-gold;
      }
    }

    .li_active {
      color: $color-black;
      background: $color-gold;
    }
  }

  .show-more__button {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
}

.country-block {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: $mobile-lg) {
    justify-content: start;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
