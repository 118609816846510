@import '../../../../../styles/variables.scss';

.about-nail-line {
  position: absolute;
  top: 151px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media screen and (max-width: $laptop-sm) {
    display: none;
  }
}