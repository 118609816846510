@import '../../../styles/variables.scss';

.second-step {
  padding: 60px 30px;

  @media screen and (max-width: $tablet) {
    padding: 0px 15px;
  }

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 87px;
  }
}

.second-step__form {
  display: grid;
  gap: 26px;
}

.second-step_header {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  align-items: baseline;
  gap: 17px;

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 24px;
  }
}

.second-step_header h2 {
  @media screen and (max-width: $tablet) {
    font-size: 34px;
    line-height: 100%;
  }
}

.second-step_header h4 {
  @media screen and (max-width: $tablet) {
    font-size: 24px;
    line-height: 140%;
  }
}

.second-step_header :last-child {
  grid-column: span 2;
}

.second-step__text-info {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;

  text-transform: uppercase;

  color: $color-grey;

  border-bottom: 1px solid $color-grey;
}

.second-step__text-info_margin {
  margin-top: 100px;
}