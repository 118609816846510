@import '../../../../../styles/variables.scss';

.personal__upload {
  display: flex;
  flex-flow: column;
  gap: 30px;

  @media screen and (max-width: $mobile-lg) {
    gap: 15px;
  }
}

.personal__upload_margin_bottom {
  margin-bottom: 100px;

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 24px;
  }
}

.personal__upload-input {
  display: none;
}

.personal__button {
  display: flex;
  justify-content: center;
  gap: 10px;
  border: 1px solid #C6B689;
  border-radius: 50px;
  padding: 13px;
}

.personal__button > span {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;

  color: $color-gold;
}

.personal__remove {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: $color-gold;
  padding-bottom: 100px;

  @media screen and (max-width: $mobile-lg) {
    padding-bottom: 24px;
  }
}

.second-step__padding {
  padding-top: 41px;
}

.uploaded {
  background: $color-gold;
}

.uploaded > span {
  color: $color-black;
}