@import '../../../../../styles/variables';

.become-partner {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid $grey-border;
  box-shadow: inset 5px 0px 28px rgba(150, 140, 114, 0.2);
  backdrop-filter: blur(13.5px);
  border-radius: 17px;
  padding: 22px 130px 43px;
  position: relative;
  margin: 0 40px;

  @media screen and (max-width: $tablet-lg) {
    padding: 22px;
    margin: 0 10px;
  }

  .title {
    text-align: center;

    @media screen and (max-width: $tablet) {
      text-align: left;
      padding-right: 40px;
    }
  }

  .close-icon {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 35px;
    max-height: 62vh;
    overflow-y: auto;
  }

  .info {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: $grey-text;
    gap: 8px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .button {
      margin: 20px 0 0;
    }

    .button-primary {
      max-width: 342px;
      width: 100%;
    }
  }
}