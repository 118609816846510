@import '../../styles/variables.scss';

.popup {
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  width: 690px;
  position: absolute;
  background: radial-gradient(69.21% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 13px;
  z-index: 10;
  padding: 22px 18px;
}

.popup-shown {
  animation: fadeIn .3s linear;
}

.popup-hidden {
  animation: fadeOut .3s linear;
}

.popup__text-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.popup__text-block > span {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  color: $color-gold;
}

.popup__text-block :nth-child(2) {
  font-size: 27px;
  line-height: 90%;
  letter-spacing: -0.03em;
  color: #D5C493;
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

@media screen and (min-width: 280px) and (max-width: 834px) {
  .popup {
    width: 90%;
    background: radial-gradient(69.21% 50% at 50% 50%, #404040 0%, #000000 100%);
  }
}