@import '../../../styles/variables';

.phone-input {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.phone-input__content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  border-bottom: 1px solid $color-gold;
  border-left: 1px solid $color-gold;
  border-right: 1px solid $color-gold;

  padding: 19px 20px;

  @media screen and (max-width: $tablet) {
    padding: 10px 8px;
  }
}

.phone-input__info {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: #5C5C51;

  @media screen and (max-width: $tablet) {
    font-size: 15px;
    line-height: 20px;
  }
}

.phone-input__error {
  font-family: Switzer;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $error-text;

  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
}

.phone-input__content > label {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 90%;

  text-transform: uppercase;

  color: $color-gold;

  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
  @media screen and (max-width: $mobile-md) {
    font-size: 13px;
  }
}

.phone-input__content > input::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: #272722;

  @media screen and (max-width: $mobile-md) {
    font-size: 17px;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}

.phone-input__content > input {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: $color-gold;

  @media screen and (max-width: $tablet) {
    max-width: 320px;
    width: 100%;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
    width: 220px;
  }

  @media screen and (max-width: $mobile-md) {
    font-size: 17px;
    width: 190px;
  }

  @media screen and (max-width: $mobile-sm) {
    width: 160px;
  }
}

.custom-phone-input__container {
  background-color: transparent !important;
}

.custom-phone-input {
  background-color: transparent !important;
  border: none !important;
  font-family: Switzer !important;
  font-weight: 300 !important;
  font-size: 27px !important;
  line-height: 90% !important;

  color: $color-gold !important;
  padding-left: 62px !important;

  @media screen and (max-width: $tablet) {
    max-width: 320px !important;
    width: 100% !important;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px !important;
    width: 220px !important;
  }

  @media screen and (max-width: $mobile-md) {
    font-size: 17px !important;
    width: 190px !important;
  }

  @media screen and (max-width: $mobile-sm) {
    width: 160px !important;
  }
}

.custom-phone-input::placeholder {
  font-family: Switzer !important;
  font-weight: 300 !important;
  font-size: 27px !important;
  line-height: 90% !important;

  color: #272722 !important;

  @media screen and (max-width: $mobile-md) {
    font-size: 17px !important;
  }
  @media screen and (max-width: $mobile-lg) {
    font-size: 13px !important;
  }
}

.react-tel-input .form-control {
  background: transparent !important;
}

.custom-phone-input__button {
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input .flag-dropdown.open {
  background-color: transparent !important;
}

.selected-flag {
  background-color: transparent !important;
}

.selected-flag:hover {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.custom-phone-input__dropdown {
  background: rgba(0, 0, 0, 0.6) !important;
  border: 1px solid #3E3E37 !important;
  backdrop-filter: blur(13.5px) !important;
  border-radius: 17px !important;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    @media screen and (min-width: 280px) and (max-width: 320px) {
      width: 135px !important;
    }
    @media screen and (min-width: 320px) and (max-width: 360px) {
      width: 155px !important;
    }
    @media screen and (min-width: 360px) and (max-width: 375px) {
      width: 175px !important;
    }
    @media screen and (min-width: 375px) and (max-width: 390px) {
      width: 185px !important;
    }
    @media screen and (min-width: 390px) and (max-width: 393px) {
      width: 180px !important;
    }
    @media screen and (min-width: 412px) and (max-width: 414px) {
      width: 191px !important;
    }
    @media screen and (min-width: 428px) and (max-width: 430px) {
      width: 199px !important;
    }
    @media screen and (min-width: 480px) and (max-width: 540px) {
      width: 235px !important;
    }
    @media screen and (min-width: 540px) and (max-width: 393px) {
      width: 255px !important;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 360px) {
    width: 180px !important;
  }

  @media screen and (min-width: 360px) and (max-width: 375px) {
    width: 180px !important;
  }
}

.custom-phone-input__dropdown > li .country-name {
  font-family: Switzer !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  
  color: #C6B689 !important;
}

.custom-phone-input__dropdown > li:hover, .country.highlight > .country-name {
  color: #000000 !important;
}

.custom-phone-input__dropdown > li .dial-code {
  font-family: Switzer !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  
  color: #616156 !important;
}

.custom-phone-input__dropdown > li:hover, .country.highlight {
  background: #C6B689 !important;
}

.custom-phone-input__dropdown > li:hover .country-name {
  color: #000000 !important;
}

.react-tel-input .selected-flag .arrow {
  border: none !important;
  background-image: url('../../../assets/order/arrow.svg') !important;
  width: 14px !important;
  height: 10px !important;
  margin-top: -4px !important;
  left: 24px !important;
  animation: rotationDown .15s linear forwards !important;
}

.react-tel-input .selected-flag .arrow.up {
  animation: rotationUp .15s linear forwards !important;
}

@keyframes rotationUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotationDown {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(-0deg);
  }
}