@import '../../../../styles/variables.scss';

.about-nail {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 6em 30px 30px 30px;
  max-width: $laptop-lg;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: $mobile-lg) {
    padding: 87px 0 0;
    margin-bottom: 133px;
  }
}

.about-nail__title {
  @media screen and (max-width: $tablet-lg) {
    display: none;
  }
}

.about-nail__content {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1.1fr;
  height: 100%;
  padding: 42px 0 42px 0;
  position: relative;
  z-index: 2;

  @media screen and (max-width: $tablet-lg) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.about-nail__left-block {
  display: flex;
  flex-flow: column;
  grid-row-start: 2;
  gap: 2em;
  z-index: -2;
  margin-top: 33px;
}

.about-nail__main-image {
  @media screen and (min-width: $mobile-sm) and (max-width: $tablet-md) {
    width: 100%;
  }
  @media screen and (min-width: $laptop-sm) and (max-width: $desktop-qhd) {
    position: absolute;
    left: 0;
    right: -60px;
    width: 110%;
  }
}

.about-nail__right-block {
  display: flex;
  flex-flow: column;
  justify-content: end;
  gap: 47px;
  grid-row-start: 2;
  position: relative;
  padding-bottom: 25px;
  padding-top: 149px;

  @media screen and (max-width: $tablet-lg) {
    padding-top: 0;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
  }

  @media screen and (min-width: $laptop-sm) and (max-width: $desktop-qhd) {
    padding-left: 60px;
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(38.5px);
  }
}

.about-nail__image {
  width: 10em;

  &_tablet {
    display: block;
    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }

  &_mobile {
    display: none;
    @media screen and (max-width: $mobile-lg) {
      display: block;
      align-self: center;
      position: absolute;
      top: -10px;
      // left: 50%;
      // transform: rotate(45deg) translateX(-50%);
      width: 134px;
    }
  }
}

.about_nail__sub_text * {
  font-family: Roundo;
  font-weight: 400;
  font-size: 27px;
  line-height: 130%;

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: $color-gold;

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}

.about-nail__text {
  max-width: 497px;

  @media screen and (max-width: $mobile-lg) {
    padding: 96px 15px 0;
  }
}

.about-nail__detail {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  column-gap: 26px;
  row-gap: 77px;

  @media screen and (max-width: $mobile-lg) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0 15px 0 71px;
    row-gap: 27px;
  }
}

.about-nail__detail :first-child {
  grid-column-start: 2;

  @media screen and (max-width: $mobile-lg) {
    grid-column-start: 1;
  }
}

.about-nail__overflow {
  width: 100%;
  height: 108%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(38.5px);
  top: 0;
  left: -45px;
  z-index: -1;

  @media screen and (min-width: $tablet-lg) {
    display: none;
  }

  @media screen and (max-width: $tablet-lg) {
    top: -40px;
    left: 0;
  }

  @media screen and (max-width: $mobile-lg) {
    height: 100%;
  }
}

.about-nail__description-block {
  display: flex;
  flex-flow: column;
}
