@import '../../styles/variables';

.intro {
	display: grid;
	justify-content: center;
	justify-items: center;

	padding-top: 30px;
}

.flashlight {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	display: grid;
	align-content: center;

	width: 45%;
	height: 95vh;
	padding: 0 68px;

	background: radial-gradient(52.05% 55.37% at 50% 50%, rgb(255 255 255 / 18.3%) 0%, rgb(255 255 255 / 0%) 100%);

	@media screen and (max-width: $mobile-lg) {
		width: 75%;
		padding: 30px 18px 0;
	}
}

.flashlight > span {
	text-align: center;
}

.flashlight-first-step {
	grid-template-columns: 1fr 6fr 1fr;
}

.flashlight-first-step p {
	text-align: center;

	color: $color-white;

	font-family: Switzer;
	font-size: 20px;
	font-weight: 400;
	line-height: 170%;
	grid-column-start: 2;
}

.flashlight-second-step {
	span {
		text-align: center;
		@media screen and (min-width: $tablet) {
			max-width: 70%;
			margin: 0 auto;
		}
	}
}

.flashlight p {
	padding: 0 36px;

	text-align: center;

	@media screen and (max-width: $tablet) {
		padding: 0;
	}
}

.flashlight__item {
	display: grid;
	grid-template-columns: 1fr 1fr;

	align-items: center;
	margin-top: 75px;
}

.flashlight__item :nth-child(1) {
	justify-self: center;
}

.flashlight__item :nth-child(2) {
	justify-self: start;
}

.fadein {
	-webkit-animation: fadein 1.8s linear forwards;
  animation: fadein 1.8s linear forwards;
}

.fadeout {
	-webkit-animation: fadeout 2.2s linear forwards;
  animation: fadeout 2.2s linear forwards;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  50% { opacity: 0; }
}