@import "../../../styles/variables";

.order-submission {
  display: flex;
  flex-direction: column;
  padding: 0 30px 148px;

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px 80px;
  }

  :last-child {
    grid-row-start: 2;
    grid-column: span 2/4;

    @media screen and (max-width: $tablet-lg) {
      grid-column: span 3;
    }
  }

  .description {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 90%;
    text-transform: uppercase;
    color: $color-gold;
    margin-bottom: 39px;
  }

  .checkbox-text {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 90%;
    color: $color-gold;
  }

  .price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    align-items: center;
  }

  .delivery-price {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 90%;
    text-transform: uppercase;
    color: $grey-text;
  }

  .delivery-price_active {
    color: $color-dark-gold;
  }

  .delivery-text {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 90%;
    color: $grey-text;
    margin: 0 0 10px 33px;
  }

  .delivery-image {
    width: 88px;
    height: 12px;
    margin: 0 0 22px 33px;
  }

  .divider {
    background: $color-grey;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
  }

  .total {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  .total__text {
    font-family: 'Roundo';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 140%;
    text-transform: uppercase;
    color: $color-white-secondary;
  }

  .total__price {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 300;
    font-size: 57px;
    line-height: 90%;
    text-align: right;
    text-transform: uppercase;
    color: $color-dark-gold;
    padding-top: 5px;
  }

  .policy {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 27px;

    a {
      color: $color-gold;
      text-decoration: underline;
    }
  }
}