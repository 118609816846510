@import '../../../../../styles/variables';

.home-program-steps-line {
  position: absolute;
  top: 393px;
  left: 47.7%;
  transform: translateX(-50%);

  @media screen and (max-width: $laptop-sm) {
    top: 293px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: $tablet-md) {
    display: none;
  }
}
