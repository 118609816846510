@import '../../../styles/variables';

.select-input {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.select-input__content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;

  border-bottom: 1px solid $color-gold;
  border-left: 1px solid $color-gold;
  border-right: 1px solid $color-gold;

  padding: 19px 20px;

  @media screen and (max-width: $tablet) {
    padding: 10px 8px;
  }

  // @media screen and (max-width: $mobile-lg) {
  //   justify-items: flex-end;
  // }
}

.select__required {
  font-family: Switzer;
  font-weight: 400;
  font-size: 26px;
  line-height: 22px;
  color: $color-gold;
}

.select-input__content > label {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 90%;

  text-transform: uppercase;

  color: $color-gold;
  text-align: left;

  @media screen and (max-width: $mobile-md) {
    font-size: 13px;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
}

.select-input__content > input::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: #272722;

  @media screen and (max-width: $mobile-md) {
    font-size: 17px;
  }
  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}


.select {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;
  width: 100%;
  text-align: right;
  letter-spacing: -0.03em;

  color: $color-gold;

  padding-right: 55px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../../../assets/order/arrow.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
  justify-self: flex-end;

  @media screen and (max-width: $tablet) {
    max-width: 320px;
    width: 100%;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
    width: 220px;
  }

  @media screen and (max-width: $mobile-md) {
    background-position: right 0.2em top 50%, 0 0;
    padding-right: 22px;
    font-size: 17px;
    width: 190px;
  }

  @media screen and (max-width: $mobile-sm) {
    width: 160px;
  }
}

/* For IE */
select::-ms-expand {
  display: none;
}
