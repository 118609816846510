@import "../../../styles/variables";

.order__portrait {
  position: relative;
  padding: 30px;

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
    margin-bottom: 27px;
  }
}

.order__portrait-overlay {
  height: 100%;
  width: 100%;
  z-index: -1;
}

.order__selected-nails {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(.6, -.6);
}

.order__svg {
  width: 200%;
  height: 100%;
  transform: scale(1, -1);
}

.order__g {
  transform: translate(0px, 13vh);
  background-image: url('../../../assets/overlay.svg')
}

.order__overlay {
	position: absolute;
	height: 100%;
	z-index: -1;
}