@import '../../../../styles/variables';

.official-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: $laptop-lg;
  margin: 0 auto;

  .wavy-text {
    @media screen and (min-width: $laptop-sm) {
      margin-bottom: 70px;
    }
  }

  .button {
    margin-top: 47px;
  }
}