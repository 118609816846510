@import '../../styles/variables.scss';

.modal-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fadein .2s linear forwards;
  z-index: 200;
}

.modal-alert__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: inset 0px 0px 10px rgba(62, 62, 55, 0.25);
  backdrop-filter: blur(13.5px);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}