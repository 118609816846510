@import '../variables.scss';

body {
	margin: 0;
	padding: 0;

	color: $color-white;
	background-color: #000;

	font-family: Roundo;
	font-size: 16px;
	font-weight: 400;
	line-height: 27.2px;
}

.no-scroll {
	height: 100%;
	overflow: hidden;
}

h1 {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: $color-white;

	font-family: Roundo;
	font-size: 170px;
	font-weight: 300;
	line-height: 170px;

	@media screen and (max-width: $mobile-lg) {
		font-size: 50px;
		line-height: 62px;
	}
}

h2, .h2 {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: $color-white;

	font-family: Roundo;
	font-size: 70px;
	font-weight: 400;
	line-height: 86px;

	@media screen and (max-width: $mobile-lg) {
		font-size: 42px;
		line-height: 52px;
	}
}

h3, .h3 {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: $color-white;

	font-family: Roundo;
	font-size: 57px;
	font-weight: 400;
	line-height: 70px;

	@media screen and (max-width: $mobile-lg) {
		font-size: 42px;
		line-height: 52px;
	}
}

h4, .h4 {
	font-family: Roundo;
	font-weight: 400;
	font-size: 30px;
	line-height: 140%;

	text-transform: uppercase;
	color: #E7E7E7;
}

span {
	letter-spacing: -0.01em;

	color: $color-white;

	font-family: Switzer;
	font-size: 17px;
	font-weight: 400;
	line-height: 170%;
}

input {
  background: transparent;
  border: none;
	outline-color: none
}

input:focus {
	outline: none
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $color-gold !important;
		font-family: Switzer;
		font-weight: 300;
		font-size: 27px;
		line-height: 90%;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  background: transparent;
  border: none;
	outline-color: none
}

select:focus {
	outline: none
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	  -webkit-text-fill-color: $color-gold !important;
		font-family: Switzer;
		font-weight: 300;
		font-size: 27px;
		line-height: 90%;
}

select:-internal-autofill-selected {
	background-color: transparent;
}

textarea {
  background: transparent;
  border: none;
	outline-color: none
}

textarea:focus {
	outline: none
}

.app {

}
