@import '../../../../../styles/variables';

.artwork__line {
  position: absolute;
  padding-left: 16px;
  z-index: -1;

  @media screen and (max-width: $tablet-lg) {
    display: none;
  }
}