@import '../../styles/variables.scss';

.button {
	display: grid;
	align-items: center;
	justify-content: center;

	padding: 10px 22px 10px;
	letter-spacing: 0.07em;
	border-radius: 57px;
	background-color: rgb(0 0 0 / 50%);

	@media screen and (min-width: 1024px) and (max-width: 2024px) {
		padding: 10px 18px 10px;
	}
}

.button-primary, .button-primary_active {
  color: $color-gold;
  border: 2px solid $color-gold;
  font-family: Roundo;
  text-transform: uppercase;
  font-weight: 400;
	font-size: 28px;
	line-height: 34px;

  transition: background-color .7s ease-out 80ms;
	z-index: 1;

  @media screen and (max-width: $mobile-lg) {
	  font-size: 23px;
	  line-height: 28px;
	  letter-spacing: 0.07em;
  }
}

.button-primary_active {
	color: $color-black;
	background-color: $color-gold;
	box-shadow: 0 1px 27px #D5C493;
}

.button-primary_grand {
	height: 69px;
	align-items: center;
  color: $color-gold;
  border: 2px solid $color-gold;
  font-family: Roundo;
  text-transform: uppercase;
  font-size: 40px;
	font-weight: 400;
	line-height: 49px;

  transition: background-color .7s ease-out 80ms;
	z-index: 1;

	@media screen and (min-width: 375px) and (max-width: 820px) {
    height: 54px;
		font-size: 38px;
		line-height: 40px;
  }

	@media screen and (min-width: 1024px) and (max-width: 2024px) {
    height: 48px;
		font-size: 1em;
		line-height: 28px;
		// @media screen and (min-height: 600px) and (max-height: 800px) {
		// 	height: 40px;
		// }
  }
}

.button-info {
  height: 41px;
  border: 2px solid $color-grey;
  color: $color-dark-grey;
  font-family: Switzer;
  text-transform: uppercase;
  font-size: 13px;
	font-weight: 400;
	line-height: 17px;

  transition: border-color .7s ease-out 80ms;
	z-index: 1;

	// @media screen and (min-height: 600px) and (max-height: 735px) {
  //   height: 32px;
  // }
}

.button-info_md {
	color: $color-dark-grey;
	border: 2px solid $color-grey;
	font-family: Roundo;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;

	transition: background-color .7s ease-out 80ms;
	z-index: 1;

	@media screen and (max-width: $mobile-lg) {
		font-size: 23px;
		line-height: 28px;
		letter-spacing: 0.07em;
	}
}

.button-primary:hover {
	color: $color-black;
	background-color: $color-gold;
	box-shadow: 0 1px 27px #D5C493;
}

.button-primary_grand:hover {
	color: $color-black;
	background-color: $color-gold;
	box-shadow: 0 1px 27px #D5C493;
}

.button-info:hover, .button-info_md:hover {
	color: $color-gold;
	border-color: $color-gold;
	box-shadow: 0 1px 27px #D5C493;
}

.button_disabled {
	color: $color-dark-grey;
	border: 2px solid #121212;
}

.button_disabled:hover {
	color: $color-dark-grey;
	border: 2px solid #121212;
	background: transparent;
}