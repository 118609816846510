@import '../../../../styles/variables';

.exclusive-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px;
  }

  .button {
    margin-top: 47px;
  }
}

.exclusive-partners .partners .content {
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.content_sm {
  justify-items: center;
}