@import '../../../../styles/variables.scss';

.statistic-table {
  display: grid;
  grid-template-rows: repeat(15, 1fr);
  grid-template-columns: repeat(7, 1fr);
  grid-auto-flow: column;
  column-gap: 20px;
  width: 100%;
  scroll-behavior: smooth;
  overflow-y: hidden;

  -webkit-animation: fadein 1.8s linear forwards;
  animation: fadein 1.8s linear forwards;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }

  @media screen and (min-width: 1024px) {
    @media screen and (min-height: 540px) and (max-height: 600px) {
      grid-template-rows: repeat(7, 1fr);
    }

    @media screen and (min-height: 600px) and (max-height: 760px) {
      grid-template-rows: repeat(9, 1fr);
    }
  
    @media screen and (min-height: 760px) and (max-height: 820px) {
      grid-template-rows: repeat(13, 1fr);
    }
  
    @media screen and (min-height: 820px) and (max-height: 900px) {
      grid-template-rows: repeat(13, 1fr);
    }
  
    @media screen and (min-height: 900px) and (max-height: 960px) {
      grid-template-rows: repeat(15, 1fr);
    }
  
    @media screen and (min-height: 960px) and (max-height: 1000px) {
      grid-template-rows: repeat(16, 1fr);
    }
  
    @media screen and (min-height: 1000px) and (max-height: 1080px) {
      grid-template-rows: repeat(17, 1fr);
    }
  }
}

.statistic-table__item {
  display: flex;
  justify-content: space-between;
  gap: 21px;
  padding: 10px 17px;
  border-bottom: 1px solid #3E3E37;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    padding: 15px 0 15px 0;
  }
}

.statistic-table__item > div {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  gap: 10px;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    gap: 8px;
  }
}

.statistic-table__item > div > img {
  width: 2em !important;
}

.statistic-table__total-purchaces {
  font-family: Switzer;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  color: $color-gold;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 40px;
    line-height: 90%;
  }

  // @media screen and (min-height: 680px) and (max-height: 820px) {
  //   font-size: 14px;
  //   line-height: 100%;
  // }
}

.statistic-table__id-text {
  font-family: Switzer;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: $color-gold;

  @media screen and (min-width: 393px) and (max-width: 820px) {
    font-size: 17px;
    line-height: 150%;
  }

  // @media screen and (min-height: 680px) and (max-height: 820px) {
  //   font-size: 14px;
  //   line-height: 100%;
  // }
}

.statistic-table__country {
  font-family: Switzer;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  color: $color-white;

  @media screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 17px;
    line-height: 100%;
  }

  // @media screen and (min-height: 680px) and (max-height: 820px) {
  //   font-size: 14px;
  //   line-height: 100%;
  // }
}

.statistic-table__nails {
  font-family: Switzer;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;

  color: $color-gold;  

  @media screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 17px;
    line-height: 150%;
  }

  // @media screen and (min-height: 680px) and (max-height: 820px) {
  //   font-size: 14px;
  //   line-height: 100%;
  // }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}