@import '../../../../styles/variables';

$slider-image-width: 864px;
$margin-horizontal: 30px;
$color-slider-dot: #8A794B;
$color-slider-dot_active: #FFFFD3;

.home-author {
  padding: 0 $margin-horizontal;
  position: relative;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px;
    margin-bottom: 87px;
  }

  .title {
    text-align: center;
    margin-bottom: 70px;

    @media screen and (max-width: $tablet-md) {
      margin-bottom: 0;
    }
  }

  .text {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    letter-spacing: -0.01em;
    color: $color-white;
    z-index: 10;
    position: relative;
  }

  .text_gold {
    color: $color-gold;
  }

  .text_lg {
    max-width: 518px;

    @media screen and (max-width: $tablet-md) {
      margin-top: 429px;
    }

    @media screen and (max-width: $mobile-lg) {
      max-width: 287px;
    }
  }

  .text_sm {
    max-width: 425px;
  }

  .text_margin {
    margin: 38px 0 117px 345px;

    @media screen and (max-width: $tablet-lg) {
      margin: 27px 0 27px 57px;
    }
  }

  .author-logo {
    max-width: 344px;
    max-height: 773px;
    width: 100%;
    height: 100%;
    position: absolute;
    right: $margin-horizontal;
    top: -40px;

    @media screen and (max-width: $tablet-lg) {
      width: 274px;
      height: 591px;
    }

    @media screen and (max-width: $tablet-md) {
      top: 40px;
    }

    @media screen and (max-width: $mobile-lg) {
      right: 50px;
    }
  }

  .slider-wrapper {
    margin-left: 173px;

    @media screen and (max-width: $laptop-sm) {
      margin-left: 0;
    }

    @media screen and (max-width: $tablet-md) {
      margin-top: 74px;
    }

    @media screen and (max-width: $mobile-lg) {
      margin-top: 0;
    }
  }

  .slider-content {
    max-width: 864px;
    width: 100%;
  }

  .slider-title {
    font-family: 'Roundo';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $color-white;
    margin-bottom: 47px;

    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }

  .slider-item {
    max-width: $slider-image-width;
    height: auto;
  }

  .slider-image {
    max-width: $slider-image-width;
    max-height: 516px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .slider-nav {
    display: flex;
    justify-content: space-between;
    margin: 17px 0 47px;

    @media screen and (max-width: $mobile-lg) {
      justify-content: center;
      margin: 23px 0 0;
    }

    .button {
      @media screen and (max-width: $mobile-lg) {
        display: none;
      }
    }
  }


  .slider-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
  }

  .dot {
    width: 8px;
    height: 8px;
    background: $color-slider-dot;
    border-radius: 8px;

    &_active {
      background: $color-slider-dot_active;
    }
  }

  .slider-divider {
    height: 1px;
    background: $color-gold;
    max-width: 1035px;
    margin-bottom: 37px;

    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }

  .thumbs {
    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }

  .thumbs__image {
    opacity: 0.4;
    width: 147px;
    height: 90px;
    object-fit: cover;

    &_active {
      opacity: 1;
    }
  }

  .divider-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }
}





