@import '../../../../styles/variables.scss';

.nail-map {
  height: 80vh;
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  grid-template-rows: 1fr 6fr;
  justify-content: space-between;
  gap: 1em;
  padding: 30px;
  padding-top: 123px;
  // cursor: none;

  -webkit-animation: fadein 2.8s linear forwards;
  animation: fadein 2.8s linear forwards;
  cursor: url('../../../../assets/circle.png'), default;	

  @media screen and (min-height: 600px) and (max-height: 800px) {
    padding: 18px 24px;
    padding-top: 123px;
    gap: .5em;
  }
}

.nail-map__mobile-header {
  display: none;
}

.nail-map__portrait {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
}

.nail-map__statistics {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: space-between;
  grid-row: 1/3;
  grid-column: 3/3;
}

.nail-map__statistics-block {
  display: flex;
  flex-flow: column;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #3E3E37;
  backdrop-filter: blur(13.5px);
  border-radius: 17px;
  padding: 20px 10px;
  z-index: 2;

  @media screen and (min-height: 600px) and (max-height: 735px) {
    padding: 6px;
  }
}

.nail-map__exclusive-partner {
  grid-column-start: 5;
  z-index: 2;
}

.nail-map__exclusive-partner-mobile, .exclusive-partner__text {
  display: none;
}

.nail-map__actions-item {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-items: flex-end;
  align-items: flex-end;
  gap: 20px;
}

.nail-map__actions-item:last-child > span {
  display: none;
}

.nail-map__zoom-info_mobile {
  display: flex;
  align-items: center;
  position: absolute;
  top: -86px;
  left: -6px;
  width: 128px;
  height: 62px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #3E3E37;
  backdrop-filter: blur(13.5px);
  border-radius: 17px;

  font-family: Switzer;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $color-gold;

  z-index: 5;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    left: -16px;
  }
}

.nail-map__zoom-info_mobile::after {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background: rgba(0, 0, 0, 0.6);
  border-left: 1px solid #3E3E37;
  border-bottom: 1px solid #3E3E37;
  position: absolute;
  z-index: 4;
  bottom: -10px;
  left: calc(50% - 10px);
}

.nail-map__zoom-info {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 124px;
  left: calc(50% - 128px / 2);
  width: 128px;
  height: 62px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #3E3E37;
  backdrop-filter: blur(13.5px);
  border-radius: 17px;

  font-family: Switzer;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $color-gold;

  z-index: 5;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    @media screen and (min-height: 550px) and (max-height: 600px) {
      bottom: 88px;
    }

    @media screen and (min-height: 600px) and (max-height: 670px) {
      bottom: 60px;
    }

    @media screen and (min-height: 670px) and (max-height: 700px) {
      bottom: 116px;
    }

    @media screen and (min-height: 700px) and (max-height: 760px) {
      bottom: 126px;
    }

    @media screen and (min-height: 760px) and (max-height: 800px) {
      bottom: 136px;
    }

    @media screen and (min-height: 800px) and (max-height: 851px) {
      bottom: 142px;
    }

    @media screen and (min-height: 851px) and (max-height: 900px) {
      bottom: 200px;
    }

    @media screen and (min-height: 900px) and (max-height: 920px) {
      bottom: 210px;
    }
    bottom: 116px;
  }

  @media screen and (min-width: 1024px) {
    @media screen and (min-height: 700px) and (max-height: 800px) {
      bottom: 80px;
    }

    @media screen and (min-height: 800px) and (max-height: 880px) {
      bottom: 100px;
    }

    @media screen and (min-height: 880px) and (max-height: 940px) {
      bottom: 110px;
    }

    @media screen and (min-height: 940px) and (max-height: 980px) {
      bottom: 120px;
    }

    @media screen and (min-height: 980px) and (max-height: 1000px) {
      bottom: 130px;
    }

    @media screen and (min-height: 1000px) and (max-height: 1070px) {
      bottom: 140px;
    }

    @media screen and (min-height: 1070px) and (max-height: 1100px) {
      bottom: 150px;
    }

    @media screen and (min-height: 1100px) and (max-height: 1200px) {
      bottom: 160px;
    }

    @media screen and (min-height: 1200px) and (max-height: 1300px) {
      bottom: 170px;
    }
  }
}

.nail-map__zoom-info::after {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background: rgba(0, 0, 0, 0.6);
  border-left: 1px solid #3E3E37;
  border-bottom: 1px solid #3E3E37;
  position: absolute;
  z-index: 4;
  bottom: -10px;
  left: calc(50% - 10px);
}

.zoom-buttons {
  display: grid;
  justify-self: center;
  grid-template-columns: 1fr 1fr;
  grid-row-start: 2;
  grid-column-start: 2;
  align-self: flex-end;
  gap: 4px;
  height: 48px;
  width: 118px;
  z-index: 2;

  position: relative;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    height: 38px;
    width: 102px;
  }
}

.zoom-buttons__highlighted {
  // box-shadow: 0 1px 27px #D5C493;
  animation: highlighted 4s infinite;
}

.zoom-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-gold;
  border-radius: 0px 50px 50px 0px;
  z-index: 4;
}

.zoom-plus > img, .zoom-minus > img {
  width: 24px;
}

.zoom-minus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-gold;
  border-radius: 50px 0px 0px 50px;
  z-index: 4;
}

@media screen and (min-width: 280px) and (max-width: 834px) {
  .nail-map {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-template-rows: 3.8fr 1fr;
    justify-content: space-between;
    gap: 8px;
    padding: 22px 14px;
    padding-top: 80px;
    // height: 100%;
    @media screen and (min-height: 500px) and (max-height: 680px) {
      padding-bottom: 0;
    }
  }

  .nail-map__portrait-wrapper {
    display: flex;
    flex-flow: column;
    grid-column: span 4;
    grid-row-start: 1;
  }

  .nail-map__mobile-header {
    display: block;
    font-family: Roundo;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    text-transform: uppercase;
    color: $color-gold;
    text-align: center;
    z-index: 2;
  }

  .nail-map__statistics {
    display: none;
  }

  .nail-map__actions-item {
    grid-column: span 3;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    gap: 2px;
  }

  .nail-map__actions-item:last-child > span {
    display: block;
    font-family: Switzer;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;

    text-align: right;
    letter-spacing: -0.01em;

    color: #808080;

    grid-column: span 2;
    z-index: 2;
  }

  .nail-map__portrait {
    top: 80px;
    height: 80%;
  }

  .nail-map__exclusive-partner-mobile {
    display: flex;
    grid-row-start: 2;
    grid-column-start: 4;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .exclusive-partner__text {
    display: flex;
    grid-column: span 2;
    grid-row-start: 3;
    justify-content: flex-end;
    align-self: center;
    font-family: Switzer;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;

    text-align: right;
    letter-spacing: -0.01em;

    color: #808080;
    z-index: 2;
  }

  .zoom-buttons {
    grid-row-start: 2;
    grid-column: span 2;
    height: 42px;
    width: 98px;
  }

  .exclusive-partner__text {
    grid-row-start: 3;
  }

  .exclusive-partner__text_start {
    grid-column: 1 / 3;
    justify-content: flex-start;
    white-space: nowrap;
  }

  .exclusive-partner__text_gold {
    color: #9D9271;
  }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes highlighted {
  0%, 100% { box-shadow: 0 1px 10px #D5C493; }
  50% { box-shadow: 0 1px 27px #D5C493; }
}