.not-found {
	display: flex;
	align-items: center;
	justify-content: center;

	&__text {
		text-align: center;
		letter-spacing: -0.01em;
		text-transform: uppercase;

		color: #F7F7F7;

		font-family: Roundo;
		font-size: 70px;
		font-weight: 400;
		font-style: normal;
		line-height: 86px;
	}
}