@import '../../../styles/variables';

.textarea {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.textarea__content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-bottom: 1px solid $color-gold;
  border-left: 1px solid $color-gold;
  border-right: 1px solid $color-gold;

  padding: 19px 20px;
}

.textarea__info {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: #5C5C51;
}

.textarea__content > label {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 90%;

  text-transform: uppercase;

  color: $color-gold;
}

.textarea__content > textarea::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: #272722;
}

.textarea__content > textarea {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: $color-gold;
}