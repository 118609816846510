@import '../../styles/variables';

@mixin mobile-sm-grid-container {
  grid-template-columns: repeat(auto-fit, minmax(57px, 1fr));
  column-gap: 119px;
  row-gap: 86px;

  @media screen and (max-width: $mobile-lg) {
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
}

.partners {
  width: 100%;

  .title {
    font-family: 'Roundo';
    font-style: normal;
    font-weight: 400;
    font-size: 47px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #F7F7F7;
    text-align: center;

    @media screen and (max-width: $mobile-lg) {
      font-size: 37px;
      line-height: 46px;
    }

    &_margin_sm {
      margin-bottom: 34px;

      @media screen and (max-width: $mobile-lg) {
        margin-bottom: 27px;
      }
    }

    &_margin_lg {
      margin-bottom: 47px;

      @media screen and (max-width: $mobile-lg) {
        margin-bottom: 17px;
      }
    }
  }

  .content {
    display: grid;
    padding: 0 162px;
    align-items: center;

    @media screen and (max-width: $tablet) {
      padding: 0 30px;
    }

    @media screen and (max-width: $mobile-lg) {
      padding: 0 15px;
    }

    &_sm {
      @include mobile-sm-grid-container;
    }

    &_lg {
      grid-template-columns: repeat(auto-fit, minmax(77px, 1fr));
      gap: 96px;
      justify-content: center;
      justify-items: center;
      align-items: center;

      @media screen and (max-width: $mobile-lg) {
        @include mobile-sm-grid-container;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo__image {

    &_sm {
      width: 57px;
      max-height: 57px;
    }

    &_lg {
      width: 77px;
      max-height: 77px;

      @media screen and (max-width: $mobile-lg) {
        width: 57px;
        max-height: 57px;
      }
    }
  }
}

.single-partner {
  justify-content: center;
}

.double-partner {
  justify-self: center;
}

// .double-partner :nth-child(2) {
//   justify-self: start;
// }