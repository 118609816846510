@import '../../styles/variables';

.divider {
	width: 1px;
	height: 177px;
	position: relative;

	background: #D5C493;
	z-index: -1;
	@media screen and (max-width: $tablet) {
		width: 2px;
		height: 127px;
	}

	&_margin {
		margin: 47px 0;

		@media screen and (max-width: $tablet) {
			margin: 25px 0;
		}
	}

	.star {
		position: absolute;
		bottom: -14px;
		left: -13px;
		scale: 1.4;
		-webkit-transform: translate3d(0,0,0);
		width: 28px;
		height: 28px;

		animation-name: star-animation;
		animation-duration: 4s;
		animation-iteration-count: infinite;
	}
}

@keyframes star-animation {
	0% {
		transform: rotate(0deg);
		scale: 1.4;
	}
	50% {
		transform: rotate(180deg);
		scale: 2.2;
	}
	100% {
		transform: rotate(360deg);
		scale: 1.4;
	}
}

//.divider_active {
//	.star {
//		opacity: 1;
//		animation-name: star-animation;
//		animation-duration: 4s;
//		animation-iteration-count: infinite;
//	}
//}