@import '../../../../styles/variables.scss';

.artwork {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 30px;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px;
  }
}

.artwork .wavy-text {
  padding: 50px;

  @media screen and (max-width: $mobile-lg) {
    padding: 27px 0 181px;
  }
}

.artwork__content {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 23px;
  padding-top: 2em;
  position: relative;

  @media screen and (max-width: $tablet-lg) {
    gap: 0px;
    padding: 0;
  }
}

.artwork__content .wavy-text {
  padding: 0px;

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
  }
}

.artwork__content-title {
  height: 300px;
  display: flex;
  align-items: flex-end;
  text-align: center;

  @media screen and (max-width: $tablet-lg) {
    height: auto;
  }
}

.artwork__content .button {
  margin-top: 72px;

  @media screen and (max-width: $tablet-lg) {
    margin-top: 27px;
    margin-bottom: 87px;
  }
}

.artwork__image {
  width: 34em;
  @media screen and (max-width: $tablet) {
    width: 100%;
    box-sizing: border-box;
    object-fit: contain;
    padding: 0 26px 0 35px;
  }
}

.artwork__image-block {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 54px;

  @media screen and (max-width: $tablet-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 37px;
  }
}

.artwork__image-block-description {
  padding-top: 7em;
  @media screen and (max-width: $tablet-lg) {
    padding: 0;
  }

  @media screen and (max-width: $mobile-lg) {
    margin-top: 37px;
  }
}

.artwork__image-block-text {
  align-self: flex-end;
  padding-bottom: 4em;

  @media screen and (max-width: $tablet-lg) {
    padding: 0 0 0 57px;
  }
}