@import '../../../../styles/variables';

.home-program-steps {
  max-width: $laptop-lg;
  padding: 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: $tablet) {
    padding: 0 15px;
  }

  .step {
    position: relative;
    z-index: 1;
  }

  .step-one {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1035px;
    margin: 0 auto;
  }

  .step-one__content {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: $mobile-lg) {
      justify-content: center;
      margin-top: 0;
      gap: 27px;
    }
  }

  .nail-image_big {
    max-width: 1035px;
    width: 100%;
    min-height: 271px;
    object-fit: cover;
  }

  .description {
    margin-top: 17px;
    max-width: 517px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .description__title-container {
    display: flex;
    gap: 12px;
  }

  .description__title {
    font-family: 'Roundo';
    font-weight: 400;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $color-white;

    &_margin_top {
      margin-top: 5px;
    }
    
    &_sm {
      @media screen and (max-width: $mobile-lg) {
        font-size: 31px;
        line-height: 40px;
      }
    }
  }

  .description__text {
    font-family: 'Roundo';
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $color-white;
    margin-top: 20px;
    max-width: 345px;
    align-self: flex-end;

    @media screen and (max-width: $tablet) {
      align-self: flex-start;
    }

    @media screen and (max-width: $mobile-lg) {
      margin-top: 17px;
    }
  }

  .step-two {
    margin-top: 148px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: $laptop-sm) {
      gap: 148px;
      flex-direction: column;
    }

    @media screen and (max-width: $tablet) {
      margin-top: 87px;
    }

    @media screen and (max-width: $mobile-lg) {
      gap: 87px;
    }

    .card:nth-child(1) {
      @media screen and (max-width: $tablet) {
        margin-right: 57px;
      }
    }

    .card:nth-child(2) {
      @media screen and (max-width: $tablet) {
        margin-left: 57px;
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
  }

  .card__image {
    max-width: 518px;
    max-height: 477px;
    width: 100%;
    height: 100%;
  }

  .card_third {
    margin-top: 233px;

    @media screen and (max-width: $laptop-sm) {
      margin: 0;
      align-self: flex-end;
    }
  }

  .step-third {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;

    @media screen and (max-width: $laptop-sm) {
      gap: 87px;
      flex-direction: column;
    }

    @media screen and (max-width: $tablet) {
      margin-top: 87px;
    }

    .card:nth-child(1) {
      @media screen and (max-width: $tablet) {
        margin-right: 57px;
      }
    }

    .card:nth-child(2) {
      @media screen and (max-width: $tablet) {
        margin-left: 57px;
      }
    }
  }

  .card_fourth {
    margin-left: 173px;

    @media screen and (max-width: 1300px) {
      margin-left: 73px;
    }

    @media screen and (max-width: $laptop-sm) {
      margin: 0;
    }
  }

  .card_fifth {
    margin-top: 200px;

    @media screen and (max-width: $laptop-sm) {
      align-self: flex-end;
      margin-top: 0;
    }
  }

  .step-fourth {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 133px;

    @media screen and (max-width: $laptop-sm) {
      margin-top: 87px;
      gap: 87px;
      flex-direction: column;
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 57px;
    }

    .card:nth-child(1) {
      @media screen and (max-width: $tablet) {
        margin-right: 57px;
      }
    }

    .card:nth-child(2) {
      @media screen and (max-width: $tablet) {
        margin-left: 57px;
      }
    }
  }

  .card_sixth {
    margin-bottom: 350px;

    @media screen and (max-width: $laptop-sm) {
      margin-bottom: 0;
      align-self: flex-start;
    }
  }

  .card_seventh {
    margin: 0 171px 0 0;

    @media screen and (max-width: 1350px) {
      margin-right: 71px;
    }

    @media screen and (max-width: $laptop-sm) {
      margin: 0;
    }
  }

  .smile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 106px 0 173px;

    @media screen and (max-width: $laptop-sm) {
      padding: 0;
    }

    @media screen and (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 87px;
    }
  }

  .smile__content {
    max-width: 842px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 73px;

    @media screen and (max-width: $tablet) {
      margin: 0 0 57px;
    }
  }

  .smile__text {
    font-family: 'Switzer';
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    letter-spacing: -0.01em;
    color: $color-white;
    max-width: 497px;

    &:last-child {
      margin-top: 47px;
    }
  }

  .smile__text_end {
    align-self: flex-end;

    @media screen and (max-width: $mobile-lg) {
      margin-left: 57px;
    }
  }

  .smile__img {
    @media screen and (max-width: $tablet) {
      transform: rotate(90deg);
    }
  }

  .divider-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
}








