@import '../../styles/variables.scss';

.container {
  width: 100%;
  -webkit-animation: fadein 2.8s linear forwards;
  animation: fadein 2.8s linear forwards;
}

.cursor {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  left: -100px;
  top: 50%;
  background-color: $color-gold;
  z-index: 10000;
  border: 2px solid $color-gold;
  height: 12px;
  width: 12px;
}

// #svg {
//   background-image: url('../../assets/overlay.webp');
// }

.cursor__hidden {
  cursor: none;
}

.focus {
  transform: translate(-12px, -8px) rotate(-15deg);
}

.pulse {
  animation: pulse 1.5s ease-out infinite;
}

.order-portrait__overlay_mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

@keyframes pulse {
  from {
    opacity: .8;
    stroke-opacity: .5;
    stroke-width: 0;
  }
  to {
    opacity: 0;
    stroke-opacity: 0;
    stroke-width: 16px;
  }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 1; }
}