@import '../../../styles/variables';

.input {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.input__content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  border-bottom: 1px solid $color-gold;
  border-left: 1px solid $color-gold;
  border-right: 1px solid $color-gold;

  padding: 19px 20px;

  @media screen and (max-width: $tablet) {
    padding: 10px 8px;
  }
}

.input__info {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: #5C5C51;

  @media screen and (max-width: $tablet) {
    font-size: 15px;
    line-height: 20px;
  }
}

.input__required {
  font-family: Switzer;
  font-weight: 400;
  font-size: 26px;
  line-height: 22px;
  color: $color-gold;
}

.input__error {
  font-family: Switzer;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $error-text;

  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
}

.input__content > label {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 90%;

  text-transform: uppercase;

  color: $color-gold;

  @media screen and (max-width: $mobile-md) {
    font-size: 13px;
  }
  @media screen and (max-width: $mobile-lg) {
    font-size: 13px;
  }
}

.input__content > input::placeholder, .input-complex__content > input::placeholder {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: #272722;

  @media screen and (max-width: $mobile-md) {
    font-size: 17px;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
  }
}

.input__content > input, .input-complex__content > input {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;

  color: $color-gold;

  @media screen and (max-width: $tablet) {
    max-width: 320px;
    width: 100%;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 17px;
    width: 220px;
  }

  @media screen and (max-width: $mobile-md) {
    font-size: 17px;
    width: 190px;
  }

  @media screen and (max-width: $mobile-sm) {
    width: 160px;
  }
}

.input-complex__content > input {
  width: 100%;
}

.input-complex__content {
  display: flex;
}