@font-face {
	font-family: Roundo;
	font-weight: 200;
	src: local('Roundo-ExtraLight'),
		url('../assets/fonts/Roundo-ExtraLight.otf') format('truetype');
}

@font-face {
	font-family: Roundo;
	font-weight: 300;
	src: local('Roundo-Light'),
		url('../assets/fonts/Roundo-Light.otf') format('truetype');
}

@font-face {
	font-family: Roundo;
	font-weight: 400;
	src: local('Roundo-Regular'),
		url('../assets/fonts/Roundo-Regular.otf') format('truetype');
}

@font-face {
	font-family: Roundo;
	font-weight: 500;
	src: local('Roundo-Medium'),
		url('../assets/fonts/Roundo-Medium.otf') format('truetype');
}

@font-face {
	font-family: Roundo;
	font-weight: 600;
	src: local('Roundo-SemiBold'),
		url('../assets/fonts/Roundo-SemiBold.otf') format('truetype');
}

@font-face {
	font-family: Roundo;
	font-weight: 700;
	src: local('Roundo-Bold'),
		url('../assets/fonts/Roundo-Bold.otf') format('truetype');
}

@font-face {
	font-family: Switzer;
	font-weight: 700;
	src: local('Switzer-Bold'),
		url('../assets/fonts/Switzer-Bold.otf') format('truetype');
}

@font-face {
	font-family: Switzer;
	font-weight: 200;
	src: local('Switzer-Light'),
		url('../assets/fonts/Switzer-Light.otf') format('truetype');
}

@font-face {
	font-family: Switzer;
	font-weight: 500;
	src: local('Switzer-Medium'),
		url('../assets/fonts/Switzer-Medium.otf') format('truetype');
}

@font-face {
	font-family: Switzer;
	font-weight: 400;
	src: local('Switzer-Regular'),
		url('../assets/fonts/Switzer-Regular.otf') format('truetype');
}

@font-face {
	font-family: Switzer;
	font-weight: 600;
	src: local('Switzer-Semibold'),
		url('../assets/fonts/Switzer-Semibold.otf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight: 700;
	src: local('Montserrat-Bold'),
		url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight: 200;
	src: local('Montserrat-Light'),
		url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight: 500;
	src: local('Montserrat-Medium'),
		url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight: 400;
	src: local('Montserrat-Regular'),
		url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight: 600;
	src: local('Montserrat-SemiBold'),
		url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}