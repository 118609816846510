@import '../../styles/variables.scss';

.footer {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: 3fr 2fr;

	border-top: 1px solid $color-gold;
	border-bottom: 1px solid $color-gold;

	@media screen and (max-width: $tablet) {
		grid-template-rows: 1fr;
	}
}

.footer :nth-child(-1n + 3) {
	grid-column: span 4;

	border-right: 1px solid $color-gold;
	border-bottom: 1px solid $color-gold;

	@media screen and (max-width: $mobile-lg) {
		padding: 0;
	}
}

.footer :nth-child(3n) {
	border-right: none;
}

.footer :nth-child(1n + 4) {
	grid-column: span 3;
	border-right: 1px solid $color-gold;

	@media screen and (max-width: $tablet) {
		grid-column: span 12;
		border-right: none;
		border-bottom: 1px solid $color-gold;
		padding: 43px 0;
	}
}

.footer :nth-child(7n) {
	grid-column: span 3;
	border-right: none;

	@media screen and (max-width: $tablet) {
		grid-column: span 12;
		border-bottom: none;
		padding: 43px 0;
	}
}

.footer__copyright {
	display: grid;
	justify-content: center;
	color: #796837;
	padding: 46px;
}

.footer__item {
	display: grid;
	align-items: center;
	justify-content: center;
	padding: 86px 46px;
	transition: background-color .7s ease-out 80ms;
	text-decoration: none;
	color: $color-dark-gold;
	font-size: 17px;

	&_big {
		font-size: 22px;

		@media screen and (max-width: $tablet) {
			font-size: 17px;
		}
	}
}

.footer__item svg {
	border-right: none !important;
	border-bottom: none !important;
}

.footer__item:hover {
	color: $color-black;
	background-color: $color-gold;
}

.footer__item:hover svg path {
	stroke: $color-black;
}

.footer__item-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: $mobile-lg) {
		height: 115px;
	}
}

.footer__item-logo {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none !important;

	& svg {
		@media screen and (max-width: $mobile-lg) {
			width: 61px;
			height: 57px;
		}
	}
}

.footer__item-list {
	display: flex;
	gap: 37px;

	&:hover {
		& a {
			color: $color-black;
		}
	}

	& a {
		font-family: 'Switzer';
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 170%;
		text-align: center;
		letter-spacing: -0.01em;
		color: $color-dark-gold;
		border: none !important;
		text-decoration: none;
	}
}

.footer__text {
	text-decoration: none;
	color: $color-white;
}