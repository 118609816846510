@import '../../../../styles/variables.scss';

.fibers {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 30px 4em 30px;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
    margin-bottom: 124px;
  }
}

.fibers__content {
  display: flex;
  gap: 4em;
  height: 100%;
  padding: 42px 0 42px 0;

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
  }
}

.fibers__left-block {
  display: grid;
  grid-template-rows: auto auto 4fr;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 4em;
  padding-right: 3em;
  position: relative;
  padding-left: 121px;

  @media screen and (max-width: $laptop-sm) {
    padding: 0;
  }

  @media screen and (max-width: $tablet-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: $mobile-lg) {
    display: none;
  }
}

.fibers__left-block > h3 {
  grid-column: span 3;
  z-index: 5;
  margin-top: 65px;
}

.fibers__main-image {
  width: 31em;
}

.fibers__main-image_mobile {
  display: none;
  @media screen and (max-width: $mobile-lg) {
    display: block;
    width: 76.5%;
    align-self: flex-start;
    margin: 27px 0 0 15px;
  }
}

.fibers__central-block {
  position: absolute;
  bottom: 0;
  left: -200px;
  z-index: 5;

  &_desktop {
    display: block;
    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }
}

.fibers__right-block {
  display: flex;
  flex-flow: column;
  justify-content: end;
  padding-bottom: 10em;
  z-index: 0;
  position: relative;
  margin-top: 100px;
  z-index: 5;
  @media screen and (max-width: $tablet-lg) {
    display: none;
  }
}

.fibers__image {
  width: 433px;
}

.fibers__text {
  z-index: 5;
  grid-column: span 2;
  position: relative;

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px;
  }
}

.fibers__sub_text, .fibers__sub_text * {
  font-family: Roundo;
  font-weight: 400;
  font-size: 17px;
  line-height: 44px;
  letter-spacing: -0.01em;
}

.fibers__detail {
  display: flex;
  grid-row-start: 3;
  grid-column: span 2/4;
  gap: 26px;
  z-index: 5;
  position: relative;
  //white-space: pre-line;

  @media screen and (max-width: $laptop-sm) {
    grid-column: span 3/4;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px 0 72px;
    margin-top: 23px;
  }
}

.fibers__overflow {
  width: 100%;
  height: 79%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(38.5px);
  top: 0;
  left: -5em;
  z-index: 4;

  @media screen and (min-width: $tablet-lg) {
    display: none;
  }

  @media screen and (max-width: $mobile-lg) {
    display: none;
  }
}

.fibers__content_mobile {
  display: none;
  position: relative;

  @media screen and (max-width: $mobile-lg) {
    display: block;
  }
}

.fibers__text-container_mobile {
  position: relative;
}

.fibers__overflow_mobile {
  display: none;

  @media screen and (max-width: $mobile-lg) {
    display: block;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(38.5px);
    height: 106%;
    top: -27px;
    left: 0;
    z-index: 1;
  }
}

.fibers__cristiano-image_mobile {
  position: absolute;
  top: 28%;
  right: 15px;
  width: 61.3%;

  @media screen and (max-width: $mobile-lg) {
    top: 31.5%;
  }

  @media screen and (max-width: $mobile-md) {
    top: 28%;
  }
}