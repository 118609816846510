@import "../../../../styles/variables";

.home-the-name {
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $tablet) {
    margin-bottom: 87px;
  }

  .title_margin {
    margin-bottom: 71px;

    @media screen and (max-width: $tablet) {
      margin-bottom: 0;
    }
  }

  .content {
    display: flex;

    @media screen and (max-width: $tablet-lg) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: $mobile-lg) {
      padding: 0 15px;
    }
  }

  .main-image__container {
    background: radial-gradient(52.1% 92.62% at 65.1% 89.87%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.370261) 85.34%, #000000 100%);
  }

  .main-image {
    max-width: 615px;
    max-height: 860px;
    background: black;
    z-index: 10;
    position: relative;
    opacity: 0.4;

    @media screen and (max-width: $laptop-sm) {
      max-width: 515px;
    }

    @media screen and (max-width: $tablet-lg) {
      width: 92vw
    }
  }

  .description {
    //background: top 85px right 0 no-repeat url("../../../../assets/home/the-name-goals.png");
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: $tablet-lg) {
      margin-top: -83px;
      z-index: 20;
    }
  }

  .description__image {
    position: absolute;
    right: 14px;
    top: 0;

    @media screen and (max-width: $tablet-lg) {
      right: 50%;
      transform: translateX(50%);
    }

    @media screen and (max-width: $mobile-lg) {
      top: 76px;
    }

    & img {
      width: 767px;
      height: 302px;

      @media screen and (max-width: $laptop-lg) {
        width: 55vw;
      }

      @media screen and (max-width: $mobile-lg) {
        height: auto;
        width: 230px;
      }
    }
  }

  .description__content_mobile_centered {
    @media screen and (max-width: $tablet) {
      display: flex;
      margin: 0 auto;
    }
  }

  .description__content {
    max-width: 398px;
    margin-left: 128px;
    z-index: 1;

    @media screen and (max-width: $tablet) {
      margin-left: 0;
    }
  }

  .description__text {
    font-family: 'Switzer';
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    letter-spacing: -0.01em;
    color: $color-white;
    margin: 70px 0;

    @media screen and (max-width: $tablet) {
      margin: 37px 0 27px;
    }
  }

  .button-container {
    width: fit-content;
  }

  .divider-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
}