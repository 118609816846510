@import '../../../../styles/variables';

.home-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 87px;
  }

  .home-partners__title {
    margin-bottom: 70px;

    @media screen and (max-width: $mobile-lg) {
      margin-bottom: 47px;
    }
  }

  .button {
    margin-top: 47px;

    @media screen and (max-width: $mobile-lg) {
      margin-top: 37px;
    }
  }

  .divider-container {
    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }

  .margin_mobile {
    @media screen and (max-width: $mobile-lg) {
      margin-bottom: 87px;
    }
  }
}