@import '../../../../styles/variables.scss';

.home-main {
  display: grid;
  justify-items: center;
  padding-top: 126px;

  @media screen and (min-width: 280px) and (max-width: 800px) {
    padding-top: 80px;
  }
  .exclusive-partner, .exclusive-partner_mobile {
    @media screen and (max-width: $mobile-lg) {
      display: none;
      padding-top: 143px;
    }
  }
}

.home__overlay {
  display: flex;
  // width: 100%;
  justify-content: center;
  position: absolute;
  z-index: -1;
  scale: 1.2;
  left: calc(50% - (702px / 2));
  transform: translate(0%, 50px);

  @media screen and (max-width: $tablet) {
    left: calc(50% - (78% / 2));
    width: 78%;
    transform: none;
  }

  @media screen and (max-width: $mobile-lg) {
    top: 25%
  }

  //& svg {
  //  @media screen and (max-width: $tablet) {
  //    width: 90%;
  //    height: 100%;
  //  }
  //}

  & img {
    max-width: 702px;
    width: 100%;
  }

  @media screen and (min-height: 600px) and (max-height: 760px) {
    scale: .98;
  }
}

.home__title {
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: "Roundo";
  font-weight: 300;
  font-style: normal;
  color: $color-gold;
}

.home__main-title {
  padding-top: 30px;
  padding-bottom: 36px;
  font-size: clamp(33px, 10.9vw, 157px);
  line-height: 160px;
  transition: 0.5s;

  @media screen and (max-width: 1367px) {
    padding-bottom: 0;
  }

  @media screen and (max-width:$mobile-lg) {
    line-height: 1;
    padding: 0;
  }
}

.home__main-title_scroll-top {
  transform: scale(0.1) translateY(-3500px);
  opacity: 0;
}

.home__main-title_scroll-down {
  transform: scale(1) translateY(0px);
  opacity: 1;
}

.home__main-subtitle {
  font-size: 57px;
  line-height: 70px;
  padding: 15px 0 30px;

  @media screen and (min-height: 600px) and (max-height: 760px) {
    font-size: 44px;
    line-height: 32px;
    padding: 15px 0 20px;
  }

  @media screen and (max-width: $mobile-lg) {
    font-size: 27px;
    line-height: 33px;
    padding-top: 50vh;
  }

  @media screen and (max-width: $mobile-sm) {
    padding-top: 42vh;
  }
}