@import '../../../styles/variables.scss';

.third-step {
  padding: 60px 30px;

  @media screen and (max-width: $tablet) {
    padding: 0px 15px;
  }
}

.third-step__form {
  display: grid;
  gap: 26px;
}

.third-step_header {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: baseline;
  gap: 17px;
}

.third-step_header h2 {
  @media screen and (max-width: $tablet) {
    font-size: 34px;
    line-height: 100%;
  }
}

.third-step_header > h4 {
  grid-column: span 2;

  @media screen and (max-width: $tablet) {
    font-size: 24px;
    line-height: 140%;
  }
}

.third-step_header :last-child {
  grid-row-start: 2;
  grid-column: span 2;

  @media screen and (max-width: $tablet) {
    grid-column: span 3;
    margin-bottom: 24px;
  }
}