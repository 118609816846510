@import '../../../../styles/variables.scss';

.home-program {
  padding: 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $tablet) {
    padding: 0 15px;
  }

  .title-description {
    display: flex;
    justify-content: center;
    margin: 46px 0 193px;

    @media screen and (max-width: $tablet) {
      margin: 20px 0 57px;
    }
  }

  .primary {
    position: relative;
    margin-bottom: 124px;

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: $mobile-lg) {
      margin-bottom: 57px;
    }
  }

  .title {
    z-index: 10;
    position: relative;

    @media screen and (max-width: $mobile-lg) {
      font-size: 9.8vw;
      line-height: 46px;
    }
  }

  .text {
    font-family: 'Switzer';
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    letter-spacing: -0.01em;
    color: $color-white;
    z-index: 10;
    position: relative;
    white-space: break-spaces;

    &_margin {
      margin: 37px 0 27px;

      @media screen and (max-width: $tablet) {
        margin: 22px 0 20px;
      }

      &_secondary_mobile {
        @media screen and (max-width: $tablet) {
          margin: 27px 0 17px;
        }
      }
    }

    &_margin_left {
      margin-left: 172px;

      @media screen and (max-width: $tablet-md) {
        margin-left: 120px;
      }

      @media screen and (max-width: $mobile-lg) {
        margin-left: 57px;
      }
    }

    &_gold {
      color: $color-dark-gold;
    }

    &_gold_width {
      max-width: 346px;
    }

    &_sm {
      max-width: 497px;
    }

    &_md {
      max-width: 518px;
    }
  }

  .image_primary {
    position: absolute;
    right: 172px;
    top: -116px;
    z-index: 0;
    object-fit: cover;
    width: 690px;
    height: 459px;

    @media screen and (max-width: $laptop-sm) {
      width: 590px;
      height: auto;
      right: 20px;
    }

    @media screen and (max-width: $tablet-md) {
      width: 490px;
      height: auto;
      right: 20px;
    }

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .image_primary_mobile {
    display: none;

    @media screen and (max-width: $tablet) {
      display: block;
      width: 76.8vw;
      display: flex;
      align-self: end;
    }

    @media screen and (max-width: $mobile-lg) {
      margin-top: 20px;
    }
  }

  .secondary {
    display: flex;
  }

  .image_secondary {
    width: 345px;
    height: 470px;
    object-fit: cover;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .image_secondary_mobile {
    display: none;

    @media screen and (max-width: $tablet) {
      display: block;
      width: 61vw;
      margin-top: 27px;
    }
  }

  .secondary-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 345px;

    @media screen and (max-width: $laptop-sm) {
      margin-left: 100px;
    }

    @media screen and (max-width: $tablet-md) {
      margin-left: 20px;
    }

    @media screen and (max-width: $tablet) {
      margin: 0 auto;
    }
  }

  .divider {
    margin: 24px 0 47px;
  }

  .divider-container {
    display: flex;
    justify-content: center;
  }

  .details {
    display: flex;
    justify-content: center;
    max-width: 1047px;
    margin: 0 auto;
  }

  .details-text {
    font-family: 'Roundo';
    font-weight: 300;
    font-size: 72px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $color-white;

    @media screen and (max-width: $tablet) {
      font-size: 11.2vw;
    }
  }

  .player-number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 131px 0 195px;

    @media screen and (max-width: $tablet) {
      margin: 85px 0 102px;
    }
  }

  .player-number-image {
    position: absolute;

    @media screen and (max-width: $tablet) {
      width: 60.8vw;
      height: 90.1vw;
    }
  }

  .player-number-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(38.5px);
    min-height: 320px;
    position: relative;
    z-index: 20;

    @media screen and (max-width: $mobile-lg) {
      min-height: 240px;
    }
  }

  .player-number-text_width {
    max-width: 497px;

    @media screen and (max-width: $mobile-lg) {
      padding: 62px 57px;
    }
  }
}



