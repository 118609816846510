@import '../../styles/variables.scss';

.check-button {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #3E3E37;
  box-shadow: inset 0px 0px 10px rgba(62, 62, 55, 0.25);
  backdrop-filter: blur(13.5px);
  border-radius: 13px;
  padding: 12px 10px;

  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;

  text-align: center;

  color: $color-gold;

  @media screen and (max-width: $tablet) {
    padding: 16px 10px;
  }
}

.check-button_active {
  border: 1px solid $color-green;
  color: $color-green;
}
