@import '../../../../styles/variables';

.home-media-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $laptop-lg;
  margin: 0 auto;

  @media screen and (max-width: $mobile-lg) {
    margin-bottom: 87px;
  }

  .wavy-text {
    @media screen and (min-width: $mobile-lg) {
      margin-bottom: 70px;
    }
  }

  .button {
    margin-top: 47px;
  }

  .divider {
    @media screen and (max-width: $mobile-lg) {
      display: none;
    }
  }
}