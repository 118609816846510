@import '../../styles/variables.scss';

@mixin fixed-position {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	transition: 0.5s;
}

.header {
	display: grid;
	grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
	// position: sticky;
	padding: 0 0 30px;
	@include fixed-position;

	@media screen and (max-width: $tablet-lg) {
		grid-template-columns: repeat(5, 1fr)
	}
}

.header_desktop {
	@media screen and (max-width: $tablet-md) {
		display: none;
	}
}

.header_visible {
	transform: translateY(0px);
}

.header_hidden {
	transform: translateY(-400px);
}

.header_background {
	background: linear-gradient(180deg, #000000 12.9%, rgba(0, 0, 0, 0) 99.95%);
	backdrop-filter: blur(3px);
}

.header .header__item-border:first-child {
	padding-left: 30px;
}

.header .header__item-border:last-child {
	padding-right: 30px;
}

.header > :nth-child(3) {
	display: grid;
	justify-items: center;
	margin-bottom: -56px;
	background: radial-gradient(43.02% 75.48% at 50% 24.52%, #000000 0%, rgba(0, 0, 0, 0.59375) 64.05%, rgba(0, 0, 0, 0) 100%);
	border-radius: 0px 0px 90px 90px;
	height: 144px;
}

.header > :nth-child(4) {
	display: grid;
	justify-items: end;
}

.header > :nth-child(5) {
	display: grid;
	justify-items: end;
	padding-right: 30px;
}

.header__item {
	display: grid;
	text-decoration: none;
	transition: color .7s ease-out 80ms;
	color: $color-white;
	z-index: 1;
	cursor: pointer;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 170%;
	letter-spacing: -0.01em;
	font-family: 'Switzer';
	align-items: center;
}

.header__item svg {
	@media screen and (min-width: 280px) and (max-width: 800px) {
		height: 48px;
	}
}

.header__item  > a {
	text-decoration: none;
  color: white;
	transition: color .7s ease-out 80ms;
}

.header__item  > a:hover {
	color: $color-gold;
}

.header__item:last-child {
	@media screen and (max-width: $tablet-md) {
		align-items: center;
	}
}

.header__item:hover {
	color: $color-gold;
}

.header__item-border {
	border-bottom: 1px solid $color-gold;
}

.header__back-button {
	grid-template-columns: auto 5fr;
	gap: 17px;
}

.header__order-list-button {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto 1fr;
	gap: 0 17px;
}

.header__purchase-text {
	grid-column: span 3;

	color: $color-green;
}

.header__order-list-button svg {
	padding-top: 4px;
}

.header_mobile {
	display: none;
	box-sizing: border-box;
	@include fixed-position;

	@media screen and (max-width: $tablet-md) {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		padding: 10px 15px 0 15px;
	}

	@media screen and (max-width: $mobile-lg) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.header__item_centered {
	justify-self: center;
}

.header__item_txt_right {
	text-align: right;

	@media screen and (max-width: $mobile-lg) {
		grid-column-start: 2;
	}
}

.header__menu {
	padding: 32px 15px 72px;
	background: $color-black;
	z-index: 200;
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.nav {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 35px;
		justify-content: flex-end;
		width: 100%;
	}

	.nav__close {
		cursor: pointer;
		border: none;
		outline: none;
		background: none;
		font-family: 'Switzer';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 170%;
		text-align: right;
		letter-spacing: -0.01em;
		color: $color-white;
	}

	.logo-container {
		flex-shrink: 0;
	}

	.exclusive-container {
		margin: 20px 0;

		& span {
			text-align: center;
			color: #A3987A;
		}

		& svg {
			justify-self: center;
		}
	}

	.links {
		display: flex;
		gap: 17px;
		flex-direction: column;
		align-items: center;
		margin-bottom: 32px;

		@media screen and (min-height: 600px) and (max-height: 700px) {
			gap: 12px;
		}
	}

	.links__text {
		font-family: 'Roundo';
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 39px;
		text-align: center;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		color: $color-gold;
		text-decoration: none;

		@media screen and (min-height: 600px) and (max-height: 700px) {
			font-size: 24px;
			line-height: 31px;
		}
	}

	.social {
		display: flex;
		gap: 37px;
		padding: 0;
		 & a {
			 font-family: 'Switzer';
			 font-style: normal;
			 font-weight: 400;
			 font-size: 17px;
			 line-height: 170%;
			 text-align: center;
			 letter-spacing: -0.01em;
			 color: $color-dark-gold;
			 text-decoration: none;
		 }
	}
}

@media screen and (min-width: 280px) and (max-width: 834px) {
	.header__order-list-button {
		align-items: center;
	}
}