@import '../../../../../../styles/variables.scss';

.country-chart {
  display: flex;
  flex-flow: column;
  margin-bottom: 19px;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    margin-bottom: 19px;
  }
}

.country-chart > span {
  font-family: Roundo;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;
  color: $color-gold;

  @media screen and (min-height: 600px) and (max-height: 735px) {
    font-size: 16px;
  }
}

.country-chart__countries-block {
  display: grid;
}

.country-chart__countries-block > span {
  font-family: 'Switzer';
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: $color-white;
}

.country-chart__country {
  text-transform: uppercase;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    font-size: 10px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    font-size: 14px;
  }
}

.country-chart__nail {
  color: $color-gold;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    font-size: 10px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    font-size: 14px;
  }
}

.country-chart__item {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  padding-top: 20px;
  gap: 7px;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    padding-top: 12px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1298px) {
    gap: 0;
    padding-top: 12px;
  }
} 

.country-chart__info {
  display: flex;
  align-items: center;
  gap: 7px;
}

.country-chart__progressbar {
  display: grid;
  justify-items: start;
  height: 2px;
  background: #121212;
}

.country-chart__progressbar-status {
  background: $color-gold;
}