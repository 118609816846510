@import '../../../../../styles/variables.scss';

.toggle-bar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 21px;
  gap: 2px;
}

.toggle-bar__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border: 1px solid $color-gold;
  border-radius: 50px;
  box-sizing: border-box;

  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

.toggle-bar__item_active {
  color: $color-black;
  background-color: $color-gold;
}

.toggle-bar__item_disabled {
  color: $color-gold;
  background-color: transparent;
}