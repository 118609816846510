@import '../../../../styles/variables.scss';

.marble {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2em 30px 10em 30px;
  max-width: $laptop-lg;
  margin: 12px auto 0;

  @media screen and (max-width: $laptop-sm) {
    padding-bottom: 0;
  }

  @media screen and (max-width: $tablet) {
    padding: 0;
    margin: 0 0 106px;
  }
}

.marble__content {
  display: flex;
  gap: 8em;
  height: 100%;
  padding: 42px 0 42px 0;

  @media screen and (max-width: $tablet-md) {
    gap: 4em;
  }

  @media screen and (max-width: $tablet) {
    gap: 0;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 0;
  }
}

.marble__left-block {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(5, 1fr);
  gap: 2em;
  position: relative;
}

.marble__main-image {
  width: 31em;

  @media screen and (max-width: $laptop-sm) {
    width: 21em;
  }

  @media screen and (max-width: $tablet) {
    width: 288px;
  }

  @media screen and (max-width: $mobile-lg) {
    margin-top: 28px;
  }
}

.marble__central-block {
  position: absolute;
  bottom: -8px;
  right: -20em;
  z-index: 3;
}

.marble__right-block {
  display: flex;
  flex-flow: column;
  justify-content: end;
  padding-bottom: 10em;
  z-index: 5;
  position: relative;

  @media screen and (max-width: $tablet) {
    padding: 0 15px 36px;
    align-items: flex-end;
  }
}

.marble__image {
  width: 20em;

  &_tablet {
    display: block;
    @media screen and (max-width: $laptop-sm) {
      display: none;
    }
  }
}

.marble__image_mobile {
  display: none;
  @media screen and (max-width: $tablet) {
    display: block;
    width: 230px;
    height: 230px;
    position: absolute;
    right: 260px;
    bottom: -95px;
    z-index: -1;
  }

  @media screen and (max-width: $mobile-lg) {
    left: 15px;
    right: 0;
  }
}

.marble__sub_text {
  font-family: Roundo;
  font-weight: 400;
  font-size: 27px;
  line-height: 130%;

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: $color-gold;
}

.marble__detail {
  display: flex;
  flex-flow: column;
  grid-row-start: 2;
  grid-column: span 3/5;
  gap: 26px;
  z-index: 7;

  @media screen and (max-width: $laptop-sm) {
    grid-column: span 5;
  }

  @media screen and (max-width: $tablet) {
    padding: 0 15px;
  }
}

.marble_mobile_left_margin {
  @media screen and (max-width: $mobile-lg) {
    margin-left: 57px;
  }
}

.marble__text {
  grid-column: span 3;
  align-self: end;
  z-index: 7;

  @media screen and (max-width: $laptop-sm) {
    grid-column: span 5;
  }

  @media screen and (max-width: $tablet) {
    padding: 0 15px;
    margin-top: 45px;
  }
}

.marble__overflow {
  width: 100%;
  height: 110.3%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(38.5px);
  top: 35px;
  right: -45px;
  z-index: 5;

  @media screen and (min-width: $tablet-lg) {
    display: none;
  }

  @media screen and (max-width: $tablet) {
    right: 0;
    z-index: 6;
  }
}