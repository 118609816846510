@import '../../styles/variables.scss';

.search {
  display: grid;
  grid-template-columns: auto 248px 5fr;
  gap: 4px;
  height: 48px;
  z-index: 2;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    height: 38px;
  }  
}

.search > svg {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8.5px);
  border-radius: 50px;
  z-index: 6;

  @media screen and (min-height: 600px) and (max-height: 800px) {
    height: 38px;
    width: 38px;
    border-radius: 50%;
  }  
}

.search__input {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: $color-white;

  border: 1px solid #616156;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  backdrop-filter: blur(8.5px);

  padding-left: 12px;
  padding-right: 12px;

  @media screen and (min-height: 600px) and (max-height: 735px) {
    font-size: 12px;
  }
}

.search__input::placeholder {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  
  color: $color-grey;

  @media screen and (min-height: 600px) and (max-height: 735px) {
    font-size: 12px;
  }
}

.search__input_fade-in {
  -webkit-animation: fadein .4s linear forwards;
  animation: fadein .4s linear forwards;
  display: grid;
  z-index: 5;
}

.search__input_mobile-overlay {
  display: none;
}

.search__input_mobile {
  display: none;

  @media screen and (min-width: 280px) and (max-width: 880px) {
    @media screen and (min-height: 600px) and (max-height: 667px) {
      bottom: 128px;
    }
  
    @media screen and (min-height: 668px) and (max-height: 740px) {
      bottom: 145px;
    }
  
    @media screen and (min-height: 741px) and (max-height: 896px) {
      bottom: 155px;
    }
  
    @media screen and (min-height: 897px) and (max-height: 915px) {
      bottom: 160px;
    }

    @media screen and (min-height: 916px) and (max-height: 1024px) {
      bottom: 200px;
    }

    @media screen and (min-height: 1025px) and (max-height: 1180px) {
      bottom: 230px;
    }
  }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; display: grid; }
  50% { opacity: 1; display: grid; }
}

@keyframes fadein {
  0% { opacity: 0; display: grid; }
  50% { opacity: 1; display: grid; }
}

.search__input_fade-out {
  -webkit-animation: fadeout .4s linear forwards;
  animation: fadeout .4s linear forwards;
  display: none;
  z-index: 0;
}

@-webkit-keyframes fadeout {
  100% { opacity: 0; display: none; }
  50% { opacity: 1; display: none; }
}
  
@keyframes fadeout {
  100% { opacity: 0; display: none; }
  50% { opacity: 1; display: none; }
}

@media screen and (min-width: 280px) and (max-width: 880px) {
  .search {
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-row-start: 2;
    align-self: flex-end;
  }

  .search__input {
    display: none;
  }

  .search__input_mobile-overlay {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.7);
    border-radius: 17px 17px 0px 0px;
  }

  .search__input_mobile {
    display: flex;
    position: absolute;
    height: 48px;
    left: 0;
    right: 0;

    font-family: Switzer;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;

    color: $color-white;

    border: 1px solid rgb(97, 97, 86);
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8.5px);
    border-radius: 50px;

    margin: 0 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
