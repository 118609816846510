@import '../../../../styles/variables.scss';

.home-intro {
  padding: 0 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: $tablet) {
    margin-bottom: 95px;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 0 15px;
  }

  .divider-container {
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
}

.home-intro__title {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.01em;
  color: $color-white;
  margin-bottom: 47px;

  @media screen and (max-width: $tablet) {
    margin-bottom: 33px;
  }
}

.home-intro__text-container {
  width: 100%;
  position: relative;
  z-index: 30;
  word-break: break-word;
}

.home-intro__text {
  font-family: 'Roundo';
  font-weight: 300;
  font-size: 11.8vw;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: $color-white;

  @media screen and (max-width: $mobile-lg) {
    font-size: 13.3vw;
  }

  &_padding {
    padding-left: 11.73%;

    @media screen and (max-width: $laptop-sm) {
      padding-left: 0;
      margin-top: 33vw;
    }
  }
}

.home-intro__blur {
  width: 863px;
  height: 867px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(38.5px);
  position: absolute;
  right: 374px;
  top: 100px;
  z-index: 10;

  @media screen and (max-width: $laptop-lg) {
    height: 707px;
  }

  @media screen and (max-width: $laptop-sm) {
    width: 53.86vw;
    height: 89.3vw;
    right: 0;
    left: 15px;
    top: 27px;
  }
}

.home-intro__image-container {
  display: flex;
  z-index: 0;
  position: absolute;
  right: 32px;
  top: 24%;

  @media screen and (max-width: $laptop-sm) {
    right: 15px;
    top: 28%;
  }
}

.home-intro__image {
  width: 516px;
  height: 470px;

  @media screen and (max-width: $laptop-sm) {
    width: 53.6vw;
    min-height: 199px;
    height: auto;
  }
}