@import "../../styles/variables";

.contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    padding-top: 143px;
    font-family: 'Roundo';
    font-style: normal;
    font-weight: 300;
    font-size: 97px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $color-gold;
    max-width: 1440px;

    @media screen and (max-width: $tablet) {
      font-size: 6.7vw;
    }
  }

  .contacts-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .links {
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 17px;
    border-bottom: 1px solid $color-dark-gold;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: $mobile-lg) {
      justify-content: center;
    }
  }

  .links__text {
    font-family: 'Switzer';
    font-weight: 400;
    font-size: 27px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-dark-gold;
    text-decoration: none;
  }

  .links__text_grey {
    color: #5C5C51;
  }

  .social {
    display: flex;
    gap: 48px;
  }

  .copyright {
    display: grid;
    justify-content: center;
    color: #796837;
    padding: 46px;
  }
}