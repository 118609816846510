.slider-button {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.07em;
  color: #FFFFFF;
}

.slider-arrow {
  padding: 10px;

  &_left {
    margin-right: 19px;
  }

  &_right {
    margin-left: 19px;
  }
}