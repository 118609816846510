@import '../../../../../../styles/variables.scss';

.filters {
  display: flex;
  flex-flow: column;
  justify-content: end;
  align-self: flex-end;
  grid-column-start: 1;
  gap: 14px;
  z-index: 3;

  label {
    font-family: Switzer;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    color: $color-gold;

    @media screen and (min-width: 1024px) and (max-width: 1298px) {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1298px) {
    gap: 10px;
  }
}

.filters, .search {
  padding-bottom: 10px;
  @media screen and (min-width: 280px) and (max-width: 1280px) {
    padding-bottom: 0;
  }
}

.filters__check-buttons {
  display: flex;
  gap: 14px;
}

.filters__switcher {
  display: flex;
  align-items: center;
  gap: 14px;
}

.filters__switcher :last-child {
  color: #616156;
}

.filters__switcher_active {
  color: $color-green !important;
}