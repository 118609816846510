@import '../../../styles/variables.scss';

.order-list {
  width: 346px;

  background: rgba(0, 0, 0, 0.9);
  border: 1px solid $color-grey;
  border-radius: 17px;
  box-shadow: inset 5px 0px 28px rgba(150, 140, 114, 0.2);
  backdrop-filter: blur(13.5px);

  padding: 30px 30px;

  position: absolute;
  right: 0;
  top: 118px;

  transition: all .7s ease-out 80ms;

  @media screen and (min-height: 500px) and (max-height: 600px) {
    height: 320px;
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    height: 70vh;
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    height: 78.6vh;
  }

  @media screen and (min-width: 412px) and (max-width: 414px) {
    height: 80vh;
  }
}

.order-list__content {
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
}

.order-list__header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: flex-end;
  padding: 15px 0 15px 0;
}

.order-list__action-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey;
  height: 46px;
}

.order-list__action-block > span {
  font-family: Roundo;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $color-gold;
}

.order-list__header-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row-start: 2;
  border-bottom: 1px solid $color-grey;
  height: 46px;
}

.order-list__header-text > span {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  text-transform: uppercase;
  color: $color-grey;
}

.order-list__item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #272722;
}

.order-list__item > span {
  font-family: Switzer;
  font-weight: 400;
  font-size: 17px;
  color: $color-gold;
}

.order-list__item > div {
  display: flex;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
}

.order-list__item > div span {
  color:  #414141;
}

.order-list__item :last-child {
  text-align: end;
} 

.order-list__item:hover {
  border-bottom: 1px solid $color-gold;
}

.order-list__item:hover > div {
  opacity: 1;
}

.order-list__total-block {
  display: grid;
  gap: 0.1em;
  padding: 1em 0 1em 0;
}

.order-list__total-block__item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;
}

.order-list__total-block__item :first-child {
  font-family: Roundo;
  font-weight: 400;
  font-size: 25px;
  line-height: 100%;

  text-transform: uppercase;

  color: $color-grey;
}

.order-list__total-block__item :last-child {
  font-family: Switzer;
  font-weight: 400;
  font-size: 30px;
  
  color: $color-gold;
}

.order-list_fade-in {
  -webkit-animation: fadein .4s linear forwards;
  animation: fadein .4s linear forwards;
  display: grid;
  z-index: 5;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; display: none; }
  50% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; display: none; }
  50% { opacity: 1; }
}

.order-list_fade-out {
  -webkit-animation: fadeout .4s linear forwards;
  animation: fadeout .4s linear forwards;
  display: none;
  z-index: 0;
}

@-webkit-keyframes fadeout {
  100% { opacity: 0; display: none; }
  50% { opacity: 1; }
}
  
@keyframes fadeout {
  100% { opacity: 0; display: none; }
  50% { opacity: 1; }
}

@media screen and (min-width: 280px) and (max-width: 834px) {
  .order-list {
    left: 0;
    right: 0;
    top: 70px;
    overflow: scroll;
    width: auto;
    align-content: flex-start;
  }

  .order-list__item > div {
    opacity: 1;
  } 

  .order-list__info {
    text-align: center;
    padding-bottom: 10px;
  }
}

.order-content {
  display: flex;
  flex-flow: column;
}


.order-list__content > div > .button {
  width: 100%;
} 