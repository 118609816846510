@import '../../styles/variables.scss';

.modal-alert__block {
  width: 346px;
  display: flex;
  flex-flow: column;
  gap: 28px;
  padding: 40px 30px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #3E3E37;
  box-shadow: inset 5px 0px 28px rgba(150, 140, 114, 0.2);
  backdrop-filter: blur(13.5px);  
  border-radius: 17px;

  font-family: Roundo;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: $color-gold;
  max-height: 85vh;
  overflow: auto;
}

.modal-alert__block > svg {
  align-self: end;
}

.modal-alert__actions {
  display: flex;
  flex-flow: row;
  gap: 28px;
}

.modal-alert__align-center {
  justify-content: center;
}

.modal-alert__align-between {
  justify-content: center;
}

.modal-alert__divider {
  width: 100%;
  height: 1px;
  background: #616156;
}

.modal-alert__action-paddings {
  padding: 22px 10px;
}
