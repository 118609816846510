$color-white: #F7F7F7;
$color-gold: #D5C493;
$color-black: #000000;
$color-grey: #616156;
$color-light-grey: #808080;
$color-dark-grey: #4F4F4F;
$color-green: #48B913;
$color-dark-gold: #C6B689;
$error-text: red;
$grey-text: #5C5C51;
$grey-border: #3E3E37;
$color-white-secondary: #E7E7E7;

// media query sizes
$mobile-sm: 360px;
$mobile-md: 390px;
$mobile-lg: 480px;
$tablet: 768px;
$tablet-md: 864px;
$tablet-lg: 1020px;
$laptop-sm: 1200px;
$laptop-lg: 1440px;
$desktop-fhd: 1920px;
$desktop-qhd: 2560px;
