@import '../../styles/variables';

.payment-modal {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  // width: 60%;
  height: 60%;

  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #3E3E37;

  box-shadow: inset 5px 0px 28px rgba(150, 140, 114, 0.2);
  backdrop-filter: blur(13.5px);

  border-radius: 17px;

  padding: 30px 130px 43px 130px;

  @media screen and (max-width: $tablet) {
    padding: 30px 30px 43px;
  }
}

.payment-modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(13.5px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
}

.payment-modal__title {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;

  color: #D5C493;
}

.payment-content {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.payment-content__item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #272722;
  padding: 20px 0;
}

.payment-content__item :first-child {
  font-family: Switzer;
  font-weight: 300;
  font-size: 20px;
  line-height: 90%;

  text-transform: uppercase;

  color: #D5C493;
}

.payment-content__item :last-child {
  font-family: Switzer;
  font-weight: 300;
  font-size: 27px;
  line-height: 90%;

  text-align: right;
  letter-spacing: -0.03em;

  color: #3E3E37;
}