@import '../../../../styles/variables.scss';

.history-book {
  display: flex;
  flex-flow: column;
  padding: 30px 30px;
  max-width: $laptop-lg;
  margin: 56px auto 0;

  @media screen and (max-width: $mobile-lg) {
    margin-top: 0;
    padding: 0 15px;
    margin-bottom: 87px;
  }
}

.history-book__title-description {
  text-align: center;

  @media screen and (max-width: $tablet-lg) {
    display: none;
  }
}

.history-book__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4em;
  padding-top: 7em;

  @media screen and (max-width: $tablet-lg) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    gap: 0;
  }
}

.history-book__description {
  padding-top: 2em;
  max-width: 497px;

  @media screen and (max-width: $tablet-lg) {
    padding: 0;
  }
}

.history-book__description_sm {
  margin: 72px 0 0 173px;
  max-width: 370px;
  padding: 0;

  @media screen and (max-width: $tablet-lg) {
    margin: 27px 0 0 57px;
  }
}

.history-book__image {
  z-index: 1;
  @media screen and (max-width: $laptop-sm) {
    width: 100%;
  }

  @media screen and (max-width: $tablet-lg) {
    margin: 27px 0 36px;
  }
}

.history-book__action {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 3em;
  padding: 40px;
  margin-top: 14em;
  border-top: 1px solid $color-gold;
  border-bottom: 1px solid $color-gold;

  @media screen and (max-width: $tablet-lg) {
    margin-top: 87px;
  }

  @media screen and (max-width: $mobile-lg) {
    padding: 38px 5px;
    gap: 52px;
  }
}

.history-book .divider {
  align-self: center;

  @media screen and (max-width: $mobile-lg) {
    display: none;
  }
}